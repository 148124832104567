import { useTranslate } from "@tolgee/react";

type Props = {
  name: string | null;
};

export const Title = ({ name }: Props) => {
  const { t } = useTranslate();
  return (
    <div className="flex flex-col pt-2">
      <label className="text-2xl text-greyscale-900 dark:text-greyscale-100 font-semibold">
        {t("signatures.careRecipientSignature", { name })}
      </label>
    </div>
  );
};
