import { ChevronLeftOutline } from "../assets/svg/Chevron";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { AuthenticatorCard } from "./AuthenticatorCard";
import { LoadingBounce } from "../loading";
import { AppType } from "./authenticatorRouter";

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
const validationSchema = z.object({
  email: z.string().regex(emailRegex, { message: "Invalid email" }),
});

type FormValues = z.infer<typeof validationSchema>;

const forgotPasswordUrl = import.meta.env.VITE_DOMAIN + "/forgotpassword";

const sendRequest = async (email: string, app: AppType) => {
  const appType = app === "mobile" ? "app" : "team";

  return fetch(forgotPasswordUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, app: appType }),
  });
};

type ForgotPasswordProps = {
  app: AppType;
};

export const ForgotPassword = ({ app }: ForgotPasswordProps) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, getValues, formState } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: state?.email || "",
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) => {
    await sendRequest(email, app)
      .then((r) => {
        if (!r.ok) {
          setError("An error occurred. Please try again.");
        } else {
          navigate("/forgot-password-done", { state: { email } });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An error occurred. Please try again.");
      });
  };
  return (
    <AuthenticatorCard>
      <form
        className="space-y-6 px-4 pb-4 pt-2"
        action="#"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Link
          to="/"
          state={{ email: getValues("email") }}
          className="flex items-center gap-2 py-3 pl-4"
        >
          <ChevronLeftOutline className="text-primary-600" />
          <div className="text-xl text-primary-700">{getValues("email")}</div>
        </Link>

        <p className="px-4 text-greyscale-900">
          Enter your email address and we will send you instructions to reset
          your password.
        </p>

        <div>
          <label
            htmlFor="email"
            className="ml-4 block text-lg font-medium text-greyscale-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              {...register("email")}
              type="email"
              autoComplete="email"
              className={classNames(
                "flex h-12 w-full items-center rounded-xl border-0 bg-primary-0 py-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset transition-colors focus:ring-2 focus:ring-inset",
                {
                  "ring-greyscale-300 placeholder:text-greyscale-400 focus:ring-primary-600":
                    !formState.errors.email,
                  "ring-red-300 placeholder:text-red-300 focus:ring-red-600":
                    formState.errors.email,
                },
              )}
            />
          </div>
          {formState.errors.email && (
            <div className="ml-4 mt-3 text-sm text-critical-800">
              {formState.errors.email.message}
            </div>
          )}
          {error && (
            <div className="ml-4 mt-3 text-sm text-critical-800">{error}</div>
          )}
        </div>

        <button
          type="submit"
          className="flex w-full items-center justify-center rounded-lg bg-primary-600 px-8 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:bg-primary-800"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? (
            <LoadingBounce className="text-primary-600" />
          ) : (
            "Continue"
          )}
        </button>
      </form>
    </AuthenticatorCard>
  );
};

type ForgotPasswordDoneProps = {
  app: AppType;
};

export const ForgotPasswordDone = ({ app }: ForgotPasswordDoneProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (state?.email) {
      setLoading(true);
      await sendRequest(state.email, app)
        .then((r) => {
          if (!r.ok) {
            setError("An error occurred. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setError("An error occurred. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!state?.email) {
      navigate("/");
    }
  }, [navigate, state?.email]);

  return state?.email ? (
    <AuthenticatorCard>
      <div className="flex flex-col gap-8 px-4 pb-4 pt-8">
        <h2 className="self-center text-2xl font-semibold text-greyscale-900">
          Check your email
        </h2>
        <p className="px-4 text-greyscale-900">
          {`Please check the email address ${state.email} for instructions to reset your password.`}
        </p>
        <div>
          <button
            type="submit"
            className="flex w-full items-center justify-center rounded-lg bg-greyscale-0 px-8 py-3 text-lg font-semibold leading-6 text-greyscale-900 shadow-sm ring-1 ring-inset ring-greyscale-200 hover:text-greyscale-500 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:text-primary-700"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <LoadingBounce className="text-primary-600" />
            ) : (
              "Resend email"
            )}
          </button>
          <Link
            to="/"
            reloadDocument
            state={{ email: state.email }}
            className="flex w-full items-center justify-center rounded-lg bg-greyscale-0 px-8 py-3 text-lg font-semibold leading-6 text-primary-700 hover:text-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:text-primary-700"
          >
            Back to dala.care
          </Link>

          {error && (
            <div className="ml-4 mt-3 text-sm text-critical-800">{error}</div>
          )}
        </div>
      </div>
    </AuthenticatorCard>
  ) : null;
};
