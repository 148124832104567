import { useParams } from "react-router-dom";

import { Activities } from "../../components/core";
import { ActivityStatus, useVisitByIdQuery } from "../../api/generated/graphql";
import { useCareContext } from "../../providers";
import { useEffect, useState } from "react";
import { visitInfo } from "../../utils/visits";
import { Status } from "../../types";

export const OtherActivities = () => {
  const { id } = useParams();
  const { viewer } = useCareContext().state;
  const [currentStatus, setCurrentStatus] = useState<Status>("pending");

  const { data } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });

  useEffect(() => {
    if (!data?.visitById) {
      return;
    }
    const visit = visitInfo(data?.visitById);

    setCurrentStatus(visit.status);
  }, [data]);

  if (!data?.visitById) {
    return <span>No visit data</span>;
  }

  const visit = visitInfo(data?.visitById);

  const notDueActivities = visit.activities.filter(
    (activity) =>
      !activity.isDue && activity.status !== ActivityStatus.Completed,
  );

  const readOnly = !data?.visitById?.visitorIds.some((id) => id === viewer?.id);

  return (
    <div className="flex mt-4 pb-10 flex-col">
      <div className="mb-20">
        <Activities
          readOnly={readOnly}
          activities={notDueActivities}
          visitStatus={currentStatus}
          visitInstanceId={data?.visitById?.id ?? ""}
          isOtherActivities={true}
        />
      </div>
    </div>
  );
};
