import classNames from "classnames";
import { Label } from "../../typography";

type Props = {
  label: string;
  required?: boolean;
  labelFor?: string;
  className?: string;
  children: React.ReactNode;
};
const FormGroup = ({
  label,
  labelFor,
  required = false,
  className,
  children,
}: Props) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 sm:grid-cols-[1fr_2fr_1fr] sm:pt-5",
        className,
      )}
    >
      <Label size="m" htmlFor={labelFor} className="px-1 py-2.5">
        {label}
        {required ? (
          <span className="text-primary-500 ml-1" aria-hidden="true">
            *
          </span>
        ) : null}
      </Label>
      {children}
    </div>
  );
};

const FormGroupStacked = ({ label, labelFor, children }: Props) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Label size="m" htmlFor={labelFor} className="px-4 pt-4">
        {label}
      </Label>
      {children}
    </div>
  );
};

export { FormGroup, FormGroupStacked };
