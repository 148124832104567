import { useMemo, useState } from "react";
import {
  AvailableUsersForChatQuery,
  TenantSettings,
  useAvailableUsersForChatQuery,
} from "../api/generated/graphql";
import Modal from "../modal/Modal";
import { useSortingOptions } from "../utils/hooks/useSortingOptions";
import { ChatterListCard } from "./ChatterListCard";
import { SearchInput } from "../search/SearchInput";
import { LoadingBounce } from "../loading";
import { Pill } from "../pill";
import { Button } from "../button";
import { useTranslate } from "@tolgee/react";
import { Label } from "../typography";
import { Close } from "../assets/icons/16/outline";
import classNames from "classnames";

type ChatterListProps = {
  show: boolean;
  setShow: (value: boolean) => void;
  onChatStartClick: (chatters: Chatter[]) => void;
  tenantSettings?: TenantSettings;
};

export type Chatter = AvailableUsersForChatQuery["users"][0];

const addToSet = <T,>(current: Set<T>, newValue: T): Set<T> => {
  return new Set([...current, newValue]);
};
const removeFromSet = <T,>(current: Set<T>, valueToRemove: T): Set<T> => {
  return new Set([...current].filter((value) => value !== valueToRemove));
};

export const ChatterList = ({
  show,
  setShow,
  tenantSettings,
  onChatStartClick,
}: ChatterListProps) => {
  const { t } = useTranslate();
  const { data, error } = useAvailableUsersForChatQuery();
  const { nameOrderFn, collator } = useSortingOptions(tenantSettings);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set<Chatter>());

  const sortedUsers = useMemo(() => {
    if (!data?.users) return [];

    return [...data.users].sort((a, b) => {
      const nameA = nameOrderFn(a);
      const nameB = nameOrderFn(b);
      if (nameA !== null && nameB !== null) {
        return collator.compare(nameA, nameB);
      } else return 0;
    });
  }, [data, nameOrderFn, collator]);

  const filteredUsers = useMemo(() => {
    if (!search) return sortedUsers;

    return sortedUsers?.filter((user) => {
      const name = nameOrderFn(user);
      if (name) {
        return name.toLowerCase().includes(search.toLowerCase());
      } else {
        return false;
      }
    });
  }, [search, sortedUsers, nameOrderFn]);

  if (error) {
    console.error(error);
  }

  if (!data) {
    return <LoadingBounce />;
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        setShow(false);
        setSearch("");
        setSelectedUsers(new Set());
      }}
    >
      <div className="flex p-3 gap-3">
        <div className="flex items-center h-12">
          <Label size="s">{t("comms.to")}:</Label>
        </div>
        <div className="flex-1 overflow-hidden">
          <SearchInput setInputValue={setSearch} inputValue={search} />
          {selectedUsers.size > 0 && (
            <div className="flex flex-wrap gap-2 mt-4 max-h-52 overflow-y-scroll">
              {[...selectedUsers].map((user) => (
                <Pill
                  size="md"
                  key={user.id}
                  color={"darkBlue"}
                  text={user.firstName ?? ""}
                  icon={
                    <button
                      className="flex items-center justify-center -ml-1 min-h-5 min-w-5"
                      onClick={() =>
                        setSelectedUsers(removeFromSet(selectedUsers, user))
                      }
                    >
                      <Close />
                    </button>
                  }
                  iconSide="right"
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="text-sm text-gray-500 h-96 overflow-y-auto flex flex-col">
        {filteredUsers?.map((user) => {
          const isSelected = selectedUsers.has(user);
          return (
            <button
              key={user.id}
              className={classNames({
                "dark:bg-primary-900 rounded-xl": isSelected,
              })}
              onClick={() => {
                setSelectedUsers(
                  isSelected
                    ? removeFromSet(selectedUsers, user)
                    : addToSet(selectedUsers, user),
                );
                setSearch("");
              }}
            >
              <ChatterListCard key={user.id} chatter={user} />
            </button>
          );
        })}
      </div>
      {selectedUsers.size > 0 && (
        <Button
          className="w-full"
          text={t("comms.startChat")}
          disabled={selectedUsers.size < 1}
          onClick={() => onChatStartClick([...selectedUsers])}
        />
      )}
    </Modal>
  );
};
