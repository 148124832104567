import { default as A } from "./A.svg?react";
import { default as B1 } from "./B1.svg?react";
import { default as B2 } from "./B2.svg?react";
import { default as C1 } from "./C1.svg?react";
import { default as C2 } from "./C2.svg?react";
import { default as D1 } from "./D1.svg?react";
import { default as D2 } from "./D2.svg?react";
import { default as E } from "./E.svg?react";
import { default as F } from "./F.svg?react";
import { default as F3 } from "./F3.svg?react";

export default { A, B1, B2, C1, C2, D1, D2, E, F, F3 };
