import {
  Channel as ChannelView,
  Window,
  MessageList,
  MessageInput,
  DateSeparatorProps,
  DefaultStreamChatGenerics,
  useMessageInputContext,
  MessageInputFlat,
} from "stream-chat-react";

import BackIcon from "@frontend/lyng/assets/icons/24/outline/chevron-left.svg?react";
import { useNavigate, useParams } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { LoadingBounce } from "@frontend/lyng/loading";
import { useDateFormatter } from "@frontend/lyng/utils/dateUtils";
import { useCareContext } from "../../providers";
import { DateTime } from "luxon";
import { MessageSimple } from "@frontend/lyng/comms";
import { Subtitle } from "./Title";
import { useEffect, useState } from "react";
import { Channel, DefaultGenerics, StreamChat } from "stream-chat";
import { calculatePresenceLastTime } from "../../utils/dateUtils";
import { useTranslate } from "@tolgee/react";
import { Button } from "@frontend/lyng/button";
import { useChatContext } from "@frontend/lyng/comms/ChatWrapper";
import { match } from "ts-pattern";
import { Empty } from "@frontend/lyng/assets/svg";
import { Label, Paragraph } from "@frontend/lyng/typography";
import { useUsersByIdsQuery } from "@frontend/lyng/api/generated/graphql";
import {
  ChannelAvatar,
  ChatName,
  nullable,
} from "@frontend/lyng/comms/CommsChannelPreview";

const Loading = () => (
  <div className="flex justify-center dark:bg-greyscale-900 m-4">
    <LoadingBounce size="m" />
  </div>
);

const CustomDateSeparator = (props: DateSeparatorProps) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { formatDate } = useDateFormatter(viewer?.tenantSettings);

  const dateTime = DateTime.fromJSDate(props.date);

  return (
    <div className="flex justify-center items-center py-2">
      <div className="px-3 py-px rounded-lg justify-start items-center gap-2.5 inline-flex">
        <div className="text-center text-xs font-normal font-inclusive leading-none">
          {formatDate(dateTime)}
        </div>
      </div>
    </div>
  );
};

const EditMessage = () => {
  const { t } = useTranslate();
  const { handleSubmit, clearEditingState } = useMessageInputContext();

  return (
    <div className="custom-edit-form w-full">
      <MessageInputFlat />
      <div className="flex gap-2">
        <Button
          className="flex-1"
          variant="secondary"
          text={t("cancel")}
          onClick={clearEditingState}
        />
        <Button
          className="flex-1"
          variant="primary"
          text={t("save")}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const UserChat = ({
  client,
  channelId,
}: {
  client: StreamChat<DefaultStreamChatGenerics>;
  channelId: string;
}) => {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(true);
  const channel = client.channel("messaging", channelId);

  useEffect(() => {
    channel.watch().finally(() => {
      setLoading(false);
    });
  }, [channel, setLoading]);

  return (
    <ChannelView
      channel={channel}
      DateSeparator={CustomDateSeparator}
      EditMessageInput={EditMessage}
    >
      <Window>
        {match({
          loading,
          noMessages: channel.state.messages.length === 0,
        })
          .with({ loading, noMessages: true }, () => (
            <div className="h-svh flex items-center justify-center">
              {loading ? (
                <LoadingBounce />
              ) : (
                <div className="flex flex-col items-center gap-10">
                  <Empty.F />
                  <Paragraph>{t("comms.nochat")}</Paragraph>
                </div>
              )}
            </div>
          ))
          .otherwise(() => (
            <div className="flex flex-col justify-end h-svh dark:bg-greyscale-900 pt-24 pb-14">
              <MessageList
                Message={MessageSimple}
                messageActions={["edit", "delete", "react", "markUnread"]}
              />
            </div>
          ))}
        <div className="mobile-chat-input sticky bottom-0">
          <MessageInput grow maxRows={3} />
        </div>
      </Window>
    </ChannelView>
  );
};

const ChatHeader = ({
  chatClient,
  channel,
}: {
  chatClient: StreamChat<DefaultGenerics>;
  channel: Channel<DefaultGenerics> | null;
}) => {
  const { t } = useTranslate();
  const [userPresence, setUserPresence] = useState("Offline");
  const navigate = useNavigate();
  const otherMembers = Object.values(channel?.state.members ?? {}).filter(
    (member) => member.user_id !== chatClient.userID,
  );

  const { data } = useUsersByIdsQuery({
    fetchPolicy: "cache-first",
    variables: {
      ids: otherMembers.map(({ user_id }) => user_id).filter(nullable),
    },
  });

  useEffect(() => {
    const fetchPresence = async () => {
      if (!channel) return;

      const state = await channel.watch({ presence: true });
      const lastActive = state.members.find(
        (member) => member.user_id !== chatClient?.userID,
      )?.user?.last_active;

      const presence = calculatePresenceLastTime(lastActive, t);

      setUserPresence(presence);
    };
    fetchPresence();
  }, [channel, chatClient?.userID, t]);

  return (
    <div className="absolute top-0 px-2 py-8 z-20 w-full">
      <div className="flex justify-start flex-row gap-2">
        <Button
          variant="secondary"
          onClick={() => {
            navigate("/chat");
            return;
          }}
          icon={BackIcon}
          iconPosition="only"
        />
        <div className="flex gap-2 overflow-hidden">
          {channel && (
            <div className="self-end">
              <ChannelAvatar data={data} loading={false} channel={channel} />
            </div>
          )}
          <div className="flex flex-col pt-2 truncate">
            <Label className="truncate text-ellipsis" size="m">
              <ChatName users={data} />
            </Label>
            <Subtitle subtitle={userPresence} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Chat = () => {
  const { id } = useParams();
  const { chatClient } = useChatContext();
  const navigate = useNavigate();
  const ff_messaging = useFeatureFlag("Messaging");

  let channel: Channel | null = null;

  if (!ff_messaging) {
    navigate("/");
    return <Loading />;
  }

  if (!id || !chatClient) {
    return <Loading />;
  }

  channel = chatClient.channel("messaging", id);

  return (
    <div className="h-screen overflow-y-clip">
      <ChatHeader chatClient={chatClient} channel={channel} />
      <UserChat client={chatClient} channelId={id} />
    </div>
  );
};
