import { useFeatureFlag } from "../../providers/FeatureFlags";
import { Signature, SignatureRef } from "@frontend/lyng/signatures/Signature";
import { LoadingBounce } from "@frontend/lyng/loading";
import { useNavigate, useParams } from "react-router";
import { useVisitByIdQuery } from "../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { useEffect, useRef, useState, useMemo } from "react";
import { Button } from "@frontend/lyng/button";
import { useSearchParams } from "react-router-dom";
import { useCareContext } from "../../providers";
import { match } from "ts-pattern";

export const SignaturePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const ff_signatures = useFeatureFlag("Signatures");
  const caregiverSignatureRef = useRef<SignatureRef>(null);
  const careRecipientSignatureRef = useRef<SignatureRef>(null);
  const [caregiverSignature, setCaregiverSignature] = useState<string | null>(
    null,
  );
  const [careRecipientSignature, setCareRecipientSignature] = useState<
    string | null
  >(null);
  const [searchParams] = useSearchParams();
  const { viewer } = useCareContext().state;

  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
    fetchPolicy: "network-only",
  });

  const eSignatureRequired = viewer?.tenantSettings.eSignatureRequired;
  const careRecipientRoles = data?.visitById?.careRecipient.careRecipientRoles;

  const caregiverSignatureRequired = useMemo(() => {
    return (
      ff_signatures &&
      careRecipientRoles?.some(
        (careRecipientRole) =>
          careRecipientRole.office.settings?.caregiverSignatureRequired,
      )
    );
  }, [careRecipientRoles, ff_signatures]);

  const careRecipientSignatureRequired = useMemo(() => {
    return (
      ff_signatures &&
      careRecipientRoles?.some(
        (careRecipientRole) =>
          careRecipientRole.office.settings?.careRecipientSignatureRequired,
      )
    );
  }, [careRecipientRoles, ff_signatures]);

  const signatures = data?.visitById?.signatures;
  const caregiverId = data?.visitById?.visitorIds[0];
  const careRecipientId = data?.visitById?.careRecipient.id;

  useEffect(() => {
    if (signatures?.length) {
      const [first, second] = signatures;

      setCareRecipientSignature(
        match(careRecipientId)
          .with(first?.signedBy.id, () => first.url)
          .with(second?.signedBy.id, () => second.url)
          .otherwise(() => null),
      );

      setCaregiverSignature(
        match(caregiverId)
          .with(first?.signedBy.id, () => first.url)
          .with(second?.signedBy.id, () => second.url)
          .otherwise(() => null),
      );
    }
  }, [
    signatures,
    careRecipientSignatureRequired,
    caregiverSignatureRequired,
    careRecipientId,
    caregiverId,
  ]);

  const Loading = () => (
    <div className="flex justify-center dark:bg-greyscale-900 m-4">
      <LoadingBounce size="m" />
    </div>
  );

  if (!ff_signatures || !eSignatureRequired) {
    navigate("/");
    return <Loading />;
  }

  if (!data?.visitById) {
    return <span>No visit data</span>;
  }

  if (loading) {
    return <span>Loading data</span>;
  }

  if (error) {
    return <span>Error loading visit data</span>;
  }

  const name = data.visitById.careRecipient.firstName;

  const navigateCaregiverSignature = () => {
    navigate({
      pathname: `/visit/${id}/signatureCaregiver`,
    });
    return;
  };
  const navigateCareRecipientSignature = () => {
    navigate({
      pathname: `/visit/${id}/signatureCareRecipient`,
    });
    return;
  };

  const navigateClockOut = () => {
    match([careRecipientSignatureRequired, caregiverSignatureRequired])
      .with([true, true], () => {
        if (careRecipientSignature && caregiverSignature) {
          navigate({
            pathname: `/visit/${id}/clockout`,
            search: searchParams.toString(),
          });
        }
      })
      .with([true, false], () => {
        if (careRecipientSignature) {
          navigate({
            pathname: `/visit/${id}/clockout`,
            search: searchParams.toString(),
          });
        }
      })
      .with([false, true], () => {
        if (caregiverSignature) {
          navigate({
            pathname: `/visit/${id}/clockout`,
            search: searchParams.toString(),
          });
        }
      });
  };

  return (
    <div className="flex flex-col grow bg-main h-full">
      <div className="flex flex-col md:flex-row grow overflow-hidden w-full">
        {caregiverSignatureRequired && (
          <div className="flex-1">
            <div className="pb-2 md:pb-0 pl-5 pt-6 text-xl text-title">
              {t("signatures.caregiverSignature")}
            </div>
            <Signature
              initialSignature={caregiverSignature}
              key={caregiverSignature}
              ref={caregiverSignatureRef}
              readOnly={true}
              isEditing={false}
              onEdit={() => navigateCaregiverSignature()}
              boxWidth="w-full"
              boxHeight="h-[25vh] md:h-[33vh]"
            />
          </div>
        )}
        {careRecipientSignatureRequired && (
          <div className="flex-1">
            <div className="pb-2 md:pb-0 pl-5 pt-6 text-xl text-title">
              {t("signatures.careRecipientSignature", { name })}
            </div>
            <Signature
              initialSignature={careRecipientSignature}
              key={careRecipientSignature}
              ref={careRecipientSignatureRef}
              readOnly={true}
              isEditing={false}
              onEdit={() => navigateCareRecipientSignature()}
              boxWidth="w-full"
              boxHeight="h-[25vh] md:h-[33vh]"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col grow justify-end">
        <div className="flex items-end px-4 py-4 bg-greyscale-800">
          <Button
            text="Submit"
            className="flex grow items-center px-4 py-4 bg-primary-900 rounded-xl"
            onClick={() => navigateClockOut()}
          />
        </div>
      </div>
    </div>
  );
};
