import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import toast from "react-hot-toast";
import {
  useClockOutMutation,
  useVisitByIdQuery,
} from "../../api/generated/graphql";
import { GradientButton } from "../../components/core";
import { useGeoLocation } from "../../hooks/geoLocation";
import { careCompleted } from "../../typewriter/segment";
import { ThankYouSlideOver } from "./ThankYouSlideOver";
import { visitInfo } from "../../utils/visits";
import { useFeatureFlag } from "../../providers/FeatureFlags";

export const ClockOut = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslate();
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const { data } = useVisitByIdQuery({
    variables: { visitInstanceId: id ?? "" },
  });
  const navigate = useNavigate();
  const textAreaRef = useRef<HTMLDivElement>(null);
  const [clockOutMutation] = useClockOutMutation();
  const [clockOutLoading, setClockOutLoading] = useState(false);
  const ff_signatures = useFeatureFlag("Signatures");

  const { getPosition } = useGeoLocation();

  useEffect(() => {
    const visitNote = localStorage.getItem("visitNote|" + id);
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      if (visitNote) {
        textAreaRef.current.innerText = visitNote;
      }
    }
  }, [id]);

  const info = data?.visitById && visitInfo(data?.visitById);
  const clockOutTime =
    searchParams.get("scheduled") !== "true"
      ? new Date().toISOString()
      : (info?.to?.toString() ?? "");

  const totalExpenses = localStorage.getItem("expenses|" + id);
  const milesDrivenToAndFrom = localStorage.getItem(
    "milesDrivenToAndFromLocation|" + id,
  );
  const milesDrivenForClient = localStorage.getItem(
    "milesDrivenForClient|" + id,
  );
  const expensesReason = localStorage.getItem("expenseReason|" + id);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (ff_signatures) {
      localStorage.removeItem("caregiverSignature");
      localStorage.removeItem("careRecipientSignature");
    }
    e.preventDefault();

    const textArea = document.getElementById("text-area") as HTMLDivElement;
    const text = textArea.innerText;

    if (!text || text === "") {
      toast.error(t("clockout.requiredVisitNote"));
      return;
    }

    setClockOutLoading(true);
    getPosition()
      .then((position) => {
        const coords = position?.coords;
        const location =
          coords?.latitude && coords.longitude
            ? {
                lat: coords.latitude,
                lng: coords.longitude,
              }
            : undefined;
        const input = {
          visitNote: text,
          expenses: [
            {
              reportedAmount: totalExpenses,
              description: expensesReason ?? "",
            },
          ],
          mileage: {
            drivenToLocationReported: milesDrivenToAndFrom,
            drivenForCareRecipientReported: milesDrivenForClient,
          },
          visitInstanceId: id ?? "",
          clockOutLocation: location,
          clockOutTime,
        };

        return clockOutMutation({
          variables: { input },
          refetchQueries: ["VisitById"],
        });
      })
      .then(() => {
        localStorage.removeItem("visitNote|" + id);
        localStorage.removeItem("expenses|" + id);
        localStorage.removeItem("milesDrivenToAndFromLocation|" + id);
        localStorage.removeItem("milesDrivenForClient|" + id);
        localStorage.removeItem("expenseReason|" + id);
        setShowThankYouModal(true);
        careCompleted({});
      })
      .catch(() => {
        toast.error(t("clockout.error"));
      })
      .finally(() => {
        setClockOutLoading(false);
      });
  };

  return (
    <>
      <div className="pointer-events-none absolute -top-1/3 z-0 flex h-full w-full items-center justify-center" />
      <form
        onSubmit={handleSubmit}
        id="noter-save-form"
        method="POST"
        className="z-10 flex h-full grow flex-col"
      >
        <div
          className="flex h-full flex-1 grow flex-col items-center justify-center px-2 align-middle text-lg text-title focus:outline-none"
          id="text-area"
          ref={textAreaRef}
          contentEditable="true"
        />

        <div className="fixed bottom-0 h-24 w-full grow items-center justify-center bg-main px-6">
          <GradientButton
            buttonType="primary"
            text={t("submit")}
            onClick={() => handleSubmit}
            loading={clockOutLoading}
          />
        </div>
      </form>
      {showThankYouModal && (
        <ThankYouSlideOver
          name={data?.visitById?.careRecipient.firstName ?? ""}
          onDone={() => navigate("/")}
        />
      )}
    </>
  );
};
