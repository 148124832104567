import { PopoverButton } from "@headlessui/react";
import { Bell } from "../assets/icons/16/outline";
import classNames from "classnames";
import { useTranslate } from "@tolgee/react";
import { NumberPill } from "../pill/NumberPill";
import { Label } from "../typography";

export const SidebarNotificationsButton = ({
  open,
  unseen,
}: {
  open: boolean;
  unseen: number;
}) => {
  const { t } = useTranslate();
  return (
    <li className="relative">
      <PopoverButton
        className={classNames(
          open ? "bg-primary-50" : "bg-greyscale-0 hover:bg-primary-0",
          "w-full flex gap-x-3 rounded px-3 py-4 items-center",
        )}
      >
        <Bell aria-hidden="true" className="text-greyscale-900" />
        <Label size="xs" className="cursor-pointer">
          {t("notifications.notifications")}
        </Label>
        {unseen > 0 && <NumberPill count={unseen} color="redSubdued" />}
      </PopoverButton>
    </li>
  );
};
