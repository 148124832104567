import classNames from "classnames";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormRegisterReturn,
  useController,
} from "react-hook-form";
import { ErrorMessage } from "../errorMessage/ErrorMessage";
import {
  Checkmark as Checkmark16,
  Hyphen as Hyphen16,
} from "../../assets/icons/16/outline";
import { Checkmark as Checkmark24 } from "../../assets/icons/24/outline";
import { Paragraph } from "../../typography";

type Props = {
  id?: string;
  size?: "s" | "l";
  fontSize?: "s" | "m" | "xs";
  value?: string;
  label: string | React.ReactNode;
  checked?: boolean;
  indeterminate?: boolean;
  ariaLabel?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
};

export const Checkbox = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn<string>
>(function Checkbox(
  {
    id,
    name,
    size = "s",
    fontSize = "s",
    onChange,
    onBlur,
    ariaLabel,
    value,
    label,
    indeterminate = false,
    checked,
    defaultChecked,
    disabled,
    required,
    errorMessage,
  },
  ref,
) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <label className="group flex" htmlFor={id}>
      <div className="flex flex-grow h-12 w-12 mr-1 flex-col">
        <div
          className={classNames(
            "group items-center py-2 px-4 flex rounded-[8px] dark:group-has-[:checked]:bg-primary-900 h-fit",
          )}
          role="checkbox"
          aria-checked={checked}
        >
          <input
            id={id}
            className="hidden peer"
            ref={(e) => {
              inputRef.current = e;
              if (typeof ref === "function") ref(e);
            }}
            name={name}
            type="checkbox"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            aria-label={ariaLabel}
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            required={required}
          />
          <div
            className={classNames(
              "border peer-checked:border-primary-700 peer-checked:bg-primary-700 peer-invalid:border-critical-600 hover:bg-greyscale-200 hover:peer-checked:bg-primary-500 peer-disabled:peer-invalid:border-critical-600 items-start dark:border-greyscale-700 dark:hover:bg-greyscale-700 dark:peer-checked:border-primary-600 dark:peer-checked:bg-primary-600 dark:peer-invalid:border-critical-600 dark:peer-disabled:peer-invalid:border-critical-600 ",
              {
                "h-7 min-w-7 rounded-md": size === "s",
                "h-9 w-9 rounded-xl ": size === "l",
                "border-greyscale-700": !errorMessage,
                "border-critical-600": !!errorMessage,
                "bg-primary-700": indeterminate,
              },
            )}
          />
          {indeterminate ? (
            <Hyphen16 className="-ml-[26px] h-6 min-w-6 text-greyscale-0" /> // Show Slash for indeterminate state
          ) : size === "l" ? (
            <Checkmark24
              className={classNames(
                "relative group-has-[:checked]:block hidden pointer-events-none",
                {
                  "-ml-[34px] h-8 w-8 text-greyscale-0 peer-disabled:text-greyscale-500":
                    size === "l",
                },
              )}
            />
          ) : (
            <Checkmark16
              className={classNames(
                "relative group-has-[:checked]:block hidden pointer-events-none",
                {
                  "-ml-[26px] h-6 min-w-6 text-greyscale-0 peer-disabled:text-greyscale-500":
                    size === "s",
                },
              )}
            />
          )}
          <div className="flex flex-col">
            <div className="ml-3">
              {label && typeof label !== "string" ? (
                <div className="text-greyscale-700 font-inclusive text-sm">
                  {label}
                </div>
              ) : (
                <Paragraph className=" text-nowrap" size={fontSize}>
                  {label}
                </Paragraph>
              )}
            </div>
          </div>
        </div>
        <div className="ml-10">
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
      </div>
    </label>
  );
});

type CheckboxesProps = {
  options: { label: string; value: string; disabled?: boolean }[];
  errorMessage?: string;
  disabled?: boolean;
  size?: "s" | "l";
};

export function Checkboxes<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseControllerProps<TFieldValues, TName> & CheckboxesProps) {
  const { field } = useController(props);
  const [value, setValue] = useState<string[]>(field.value || []);

  return (
    <div className="flex flex-col gap-0.5">
      {props.options.map((option) => (
        <div className="px-2" key={`${field.name}-${option.value}`}>
          <Checkbox
            size={props.size}
            id={`${option.value}`}
            name={field.name}
            key={`${field.name}-${option.value}`}
            onChange={(e) => {
              const valueCopy = new Set(value);

              if (e.target.checked) {
                valueCopy.add(option.value);
              } else {
                valueCopy.delete(option.value);
              }
              const valueCopyArr = Array.from(valueCopy);
              field.onChange(valueCopyArr);

              // update local state
              setValue(valueCopyArr);
              return Promise.resolve();
            }}
            onBlur={() => Promise.resolve(field.onBlur())}
            label={option.label}
            value={option.value}
            checked={value.includes(option.value)}
            disabled={option.disabled || props.disabled}
          />
        </div>
      ))}
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </div>
  );
}
