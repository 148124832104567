import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Fragment, ReactNode, useRef } from "react";
import { Button, Variant } from "../../button";
import { ChevronDownFilled } from "../../assets/svg/Chevron";
import { Label } from "../../typography";
import { match } from "ts-pattern";
import { useMediaQuery } from "../../utils/hooks/useMediaQuery";

type OptionProp = {
  id: string;
  label: string;
  icon?: ReactNode;
  onClick: () => void;
};

type Props = {
  options: OptionProp[];
  selection?: string;
  iconPosition?: "left" | "right" | "only";
  size?: "sm" | "md" | "lg";
  icon?: React.ElementType;
  itemLeftIcon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
  showRighCheckmark?: boolean;
  text?: string;
  buttonVariant?: Variant;
  onChange?: (selectedOption: string) => void;
  keepOpenOnClick: boolean;

  // positions are for the menu that appears when the button is clicked
  verticalPosition?: "top" | "bottom";
  horizontalPosition?: "start" | "end";
};

export const Dropdown = ({
  selection,
  options,
  text,
  icon,
  size = "md",
  iconPosition,
  itemLeftIcon,
  selectedIcon,
  onChange,
  buttonVariant = "secondary",
  keepOpenOnClick = false,
  verticalPosition = "bottom",
  horizontalPosition = "start",
}: Props) => {
  const matches = useMediaQuery("(min-width: 768px)");

  const leftIconWithDefault = !icon && !text ? EllipsisVerticalIcon : icon;
  const rightIconWithDefault = !icon && text ? ChevronDownFilled : icon;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const buttonIcon = match({ iconPosition })
    .with({ iconPosition: "left" }, () => leftIconWithDefault)
    .with({ iconPosition: "right" }, () => rightIconWithDefault)
    .with({ iconPosition: "only" }, { iconPosition: undefined }, () => icon)
    .exhaustive();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton
          ref={buttonRef}
          as={Button}
          variant={buttonVariant}
          size={size}
          icon={buttonIcon}
          iconPosition={iconPosition}
          text={text}
        />
      </div>
      <Transition as={Fragment}>
        <div>
          <div className="fixed block top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10 md:hidden" />
          <MenuItems
            className={classNames(
              "md:absolute z-20 md:px-1 md:py-0 md:mt-2 md:w-56 md:origin-top-right md:overflow-hidden md:rounded-xl md:bg-greyscale-0 md:shadow-lg md:ring-1 md:ring-black md:ring-opacity-5 md:focus:outline-none fixed bottom-0 h-fit left-0 w-full items-center justify-center dark:bg-greyscale-800 px-4 py-8",
            )}
            anchor={
              matches
                ? { to: `${verticalPosition} ${horizontalPosition}` }
                : false
            }
          >
            <div className="flex flex-col gap-1 py-1">
              {options.map((option) => (
                <MenuItem key={option.id}>
                  {({ focus: hover }) => (
                    <button
                      onClick={(e) => {
                        option.onClick();
                        onChange && onChange(option.id);

                        if (keepOpenOnClick) {
                          e.preventDefault();
                        }
                      }}
                      className={classNames(
                        "h-12 w-full cursor-pointer p-3 text-sm text-left md:rounded rounded-xl flex flex-row",
                        {
                          "bg-primary-50 dark:bg-primary-900": hover,
                        },
                      )}
                    >
                      <div className="flex items-center grow">
                        {itemLeftIcon && itemLeftIcon}
                        <Label size="s" className="cursor-pointer">
                          {option.label}
                        </Label>
                      </div>
                      {selection === option.id && selectedIcon && (
                        <div className="flex items-center justify-end dark:text-white text-black">
                          {selectedIcon}
                        </div>
                      )}
                    </button>
                  )}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </div>
      </Transition>
    </Menu>
  );
};
