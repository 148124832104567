import { useRef } from "react";
import { z } from "zod";

import { Time } from "@internationalized/date";
import { useDateSegment, useTimeField } from "@react-aria/datepicker";
import {
  DateFieldState,
  DateSegment,
  useTimeFieldState,
} from "@react-stately/datepicker";
import { DateSettings, useDateFormatter } from "../../utils/dateUtils";
import classNames from "classnames";
import { DateTime } from "luxon";
import { ErrorMessage } from "../errorMessage/ErrorMessage";

export const timeSchemaShape = {
  hour: z.number().min(0).max(23),
  minute: z.number().min(0).max(59),
};

const timeSchema = z.object(timeSchemaShape);
export type TimeInput = z.infer<typeof timeSchema>;

export const parseTime = (time: string): TimeInput | null => {
  const dt = DateTime.fromISO(time);
  if (!dt.isValid) {
    return null;
  }

  return {
    hour: dt.hour,
    minute: dt.minute,
  };
};

type Props = {
  id?: string;
  dateSettings: DateSettings;
  name?: string;
  dataTestId?: string;
  value: TimeInput | null;
  onChange: (value: TimeInput | null) => void;
  onBlur: () => void;
  invalid?: boolean;
  disabled?: boolean;
  alignRight?: boolean;
  editView?: boolean;
  className?: string;
  errorMessage?: string;
  subtext?: string;
};

export function TimePicker({
  id,
  dateSettings,
  name,
  dataTestId,
  value,
  onChange,
  onBlur,
  disabled,
  alignRight,
  editView = true,
  className,
  errorMessage,
}: Props) {
  const { meridiem } = useDateFormatter(dateSettings);

  const state = useTimeFieldState({
    // default to IS if no country is provided
    locale: dateSettings?.country ?? "IS",
    hourCycle: meridiem ? 12 : 24,
    value: value ? new Time(value.hour, value.minute) : null,
    onChange: (value) => {
      onChange(value ? { hour: value.hour, minute: value.minute } : null);
    },
    onBlur,
    isDisabled: disabled || !editView,
  });

  const ref = useRef(null);
  const { fieldProps } = useTimeField(
    {
      isDisabled: disabled || !editView,
      name,
      id,
      "aria-label": name,
    },
    state,
    ref,
  );

  return (
    <div
      className={classNames("relative rounded-xl", className, {
        "shadow-sm": editView,
      })}
    >
      <div
        {...fieldProps}
        ref={ref}
        data-test-id={dataTestId}
        className={classNames(
          "flex h-12 w-full items-center rounded-xl border border-gray-300 px-4 transition-colors dark:text-white dark:border-greyscale-700",
          {
            "border-gray-300 focus:border-primary-500 focus-within:ring-primary-500 dark:focus-within:border-primary-500 dark:focus-within:ring-primary-500 active:border-primary-500 dark:active:border-primary-500 dark:active:ring-primary-500":
              !errorMessage && editView,
            "border-red-300 dark:border-red-500 pr-10 text-red-900 placeholder-red-300 focus-within:border-red-500 focus-within:outline-none focus-within:ring-red-500":
              !!errorMessage && editView,
            "border-transparent bg-transparent hover:border-transparent":
              !editView,
            "justify-end": !!alignRight,
            "bg-greyscale-0 dark:bg-transparent": editView,
          },
        )}
      >
        {state.segments.map((segment, i) => (
          <Segment key={i} segment={segment} state={state} />
        ))}
      </div>
      {errorMessage && (
        <div className="pt-2">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </div>
  );
}

function Segment({
  segment,
  state,
}: {
  segment: DateSegment;
  state: DateFieldState;
}) {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue != null
            ? String(segment.maxValue).length + "ch"
            : "",
      }}
      className={`group box-content h-fit rounded-sm px-0.5 text-right text-lg tabular-nums outline-none focus:bg-primary-500 focus:text-white ${
        !segment.isEditable
          ? "text-gray-500"
          : "text-gray-800 dark:text-greyscale-100"
      }`}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className={classNames(
          "pointer-events-none block w-full text-center text-lg italic text-gray-500 group-focus:text-white dark:text-greyscale-200",
          {
            invisible: !segment.isPlaceholder,
            "h-0": !segment.isPlaceholder,
          },
        )}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
}
