import {
  ChannelList,
  useChatContext as useStreamChatContext,
} from "stream-chat-react";
import { useCareContext } from "../../providers";
import {
  ChatterList,
  CommsChannelList,
  CommsChannelPreview,
} from "@frontend/lyng/comms";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { LoadingBounce } from "@frontend/lyng/loading";
import { Button } from "@frontend/lyng/button";
import { Plus } from "@frontend/lyng/assets/icons/24/outline";
import {
  usePrivateChannelCreateMutation,
  ViewerQuery,
} from "../../api/generated/graphql";
import { Chatter } from "@frontend/lyng/comms/ChatterList";
import { useChatContext } from "@frontend/lyng/comms/ChatWrapper";

const List = ({ viewer }: { viewer: ViewerQuery["viewer"] }) => {
  const [show, setShow] = useState(false);
  const { channel, setActiveChannel, client } = useStreamChatContext();
  const [privateChannelCreateMutation] = usePrivateChannelCreateMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!channel) return;

    navigate(`/chat/${channel.id}`);
    setActiveChannel(undefined);
  }, [channel, navigate, setActiveChannel]);

  const onChatStartClick = async (chatters: Chatter[]) => {
    const { data } = await privateChannelCreateMutation({
      variables: { input: { members: chatters.map(({ id }) => id) } },
    });

    if (data?.privateChannelCreate) {
      navigate(`/chat/${data.privateChannelCreate.id}`);
    }
  };

  const filters = { members: { $in: [viewer.id] }, type: "messaging" };
  const options = { presence: true, state: true, watch: true };
  return (
    <>
      <div className="absolute top-0 right-0 py-8 pr-6 mt-2 z-20">
        <Button
          variant="primary"
          onClick={() => setShow(true)}
          icon={Plus}
          iconPosition="only"
        />
      </div>
      {client && client.user ? (
        <ChannelList
          List={CommsChannelList}
          Preview={CommsChannelPreview}
          filters={filters}
          options={options}
          showChannelSearch={true}
          sendChannelsToList={false}
          setActiveChannelOnMount={false}
        />
      ) : (
        <div className="flex justify-center dark:bg-greyscale-900 m-4">
          <LoadingBounce size="m" />
        </div>
      )}
      <ChatterList
        show={show}
        setShow={setShow}
        onChatStartClick={onChatStartClick}
      />
    </>
  );
};

export const ChatList = () => {
  const {
    state: { viewer },
  } = useCareContext();

  const ff_messaging = useFeatureFlag("Messaging");

  const navigate = useNavigate();

  const { chatClient } = useChatContext();

  if (!ff_messaging) {
    navigate("/");

    return;
  }

  if (!viewer || !chatClient) {
    return (
      <div className="flex justify-center dark:bg-greyscale-900 m-4">
        <LoadingBounce size="m" />
      </div>
    );
  }

  return <List viewer={viewer} />;
};
