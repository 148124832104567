import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import classNames from "classnames";

type SignatureProps = {
  readOnly?: boolean;
  isEditing?: boolean;
  boxWidth?: string;
  boxHeight?: string;
  onEdit?: () => void;
  initialSignature?: string | null;
};

export type SignatureRef = {
  clear: () => void;
  getSavedSignature: () => string | null;
};

const getBoundingBox = (imageData: ImageData) => {
  const { width, height, data } = imageData;
  let minX = width,
    minY = height,
    maxX = 0,
    maxY = 0;
  let found = false;
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const alpha = data[index + 3];
      if (alpha > 0) {
        if (!found) {
          minX = x;
          minY = y;
          maxX = x;
          maxY = y;
          found = true;
        } else {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }
  }
  return found
    ? { x: minX, y: minY, width: maxX - minX + 1, height: maxY - minY + 1 }
    : { x: 0, y: 0, width: 0, height: 0 };
};

export const Signature = forwardRef<SignatureRef, SignatureProps>(
  (
    {
      readOnly = true,
      isEditing = false,
      boxWidth = "w-96",
      boxHeight = "h-full",
      onEdit,
      initialSignature = null,
    },
    ref,
  ) => {
    const sigCanvasRef = useRef<SignatureCanvas>(null);
    const [penColor, setPenColor] = useState("black");
    const [savedSignature, setSavedSignature] = useState<string | null>(
      initialSignature,
    );
    const [showPlusSign, setShowPlusSign] = useState(true);
    const [isCanvasEmpty, setIsCanvasEmpty] = useState(!initialSignature);
    const [signatureLoaded, setSignatureLoaded] = useState(false);

    useImperativeHandle(ref, () => ({
      clear: () => {
        if (sigCanvasRef.current) {
          sigCanvasRef.current.clear();
          setSavedSignature(null);
          setIsCanvasEmpty(true);
        }
      },
      getSavedSignature: () => {
        if (!sigCanvasRef.current || sigCanvasRef.current.isEmpty())
          return null;

        const canvas = sigCanvasRef.current.getCanvas();
        const context = canvas.getContext("2d");
        if (!context) return null;
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height,
        );
        const { x, y, width, height } = getBoundingBox(imageData);
        if (width > 0 && height > 0) {
          const croppedCanvas = document.createElement("canvas");
          croppedCanvas.width = width;
          croppedCanvas.height = height;
          const croppedCtx = croppedCanvas.getContext("2d");
          if (croppedCtx) {
            croppedCtx.putImageData(imageData, -x, -y);
            return croppedCanvas.toDataURL();
          }
        }

        return null;
      },
    }));

    const updatePenColor = () => {
      const darkModeDataMode =
        document.documentElement.getAttribute("data-mode") === "dark";
      const darkModeSelector =
        document.documentElement.classList.contains("dark");
      const darkMode = darkModeDataMode || darkModeSelector;
      if (readOnly) {
        setPenColor(darkMode ? "#BCC9FE" : "#2D1C9E");
      } else {
        setPenColor(darkMode ? "#F4F4F5" : "#040406");
      }
    };

    useEffect(() => {
      updatePenColor();
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "data-mode"
          ) {
            updatePenColor();
          }
        }
      });
      observer.observe(document.documentElement, {
        attributes: true,
      });
      return () => {
        observer.disconnect();
      };
    }, [readOnly]);

    useEffect(() => {
      const loadSignature = () => {
        if (sigCanvasRef.current) {
          const canvas = sigCanvasRef.current.getCanvas();
          const context = canvas.getContext("2d");
          if (readOnly) {
            sigCanvasRef.current.off();
            if (savedSignature && context) {
              const img = new Image();
              img.src = savedSignature;
              img.onload = async () => {
                context.clearRect(0, 0, canvas.width, canvas.height);
                let imgWidth = img.width;
                let imgHeight = img.height;
                const targetWidth = canvas.width;
                const targetHeight = canvas.height;
                if (imgWidth > targetWidth || imgHeight > targetHeight) {
                  const scaleRatio = Math.min(
                    targetWidth / imgWidth,
                    targetHeight / imgHeight,
                  );
                  imgWidth *= scaleRatio;
                  imgHeight *= scaleRatio;
                }
                const offsetX = (targetWidth - imgWidth) / 2;
                const offsetY = (targetHeight - imgHeight) / 2;
                await new Promise<void>((resolve) => {
                  requestAnimationFrame(() => {
                    context.drawImage(
                      img,
                      offsetX,
                      offsetY,
                      imgWidth,
                      imgHeight,
                    );
                    context.globalCompositeOperation = "source-in";
                    context.fillStyle = penColor;
                    context.fillRect(0, 0, canvas.width, canvas.height);
                    context.globalCompositeOperation = "source-over";
                    resolve();
                  });
                });
                setSignatureLoaded(true);
              };
            }
          } else if (isEditing) {
            sigCanvasRef.current.on();
          }
        }
      };
      loadSignature();
      window.addEventListener("resize", loadSignature);
      return () => window.removeEventListener("resize", loadSignature);
    }, [isEditing, readOnly, savedSignature, penColor, signatureLoaded]);

    useEffect(() => {
      const resizeCanvas = () => {
        if (sigCanvasRef.current) {
          const canvas = sigCanvasRef.current.getCanvas();
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;
        }
      };

      resizeCanvas();
      window.addEventListener("resize", resizeCanvas);
      return () => window.removeEventListener("resize", resizeCanvas);
    }, []);

    const handleEndStroke = () => {
      if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
        const dataUrl = sigCanvasRef.current.toDataURL();
        setSavedSignature(dataUrl);
      }
    };

    const onClickPlusSign = () => {
      setShowPlusSign(false);
      if (onEdit) {
        onEdit();
      }
    };

    return (
      <div className="p-4">
        <div className={`${boxWidth} ${boxHeight} relative`}>
          {readOnly && isCanvasEmpty && showPlusSign && (
            <div
              className="absolute inset-0 flex items-center justify-center cursor-pointer"
              onClick={onClickPlusSign}
            >
              <span className="text-4xl text-gray-500">+</span>
            </div>
          )}
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor={penColor}
            backgroundColor="transparent"
            onEnd={handleEndStroke}
            canvasProps={{
              className: classNames(
                "rounded-lg text-black dark:text-white w-full h-full",
                readOnly && !isCanvasEmpty
                  ? "bg-transparent"
                  : "bg-greyscale-0 dark:bg-greyscale-800 border border-dashed border-greyscale-700",
              ),
            }}
          />
        </div>
      </div>
    );
  },
);
