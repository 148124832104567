import classNames from "classnames";
import { match } from "ts-pattern";
import { forwardRef } from "react";
import { LoadingBounce } from "../loading";

export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "critical"
  | "quaternary";
type Size = "sm" | "md" | "lg";
type Props = {
  id?: string;
  variant?: Variant;
  size?: Size;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconPosition?: "left" | "right" | "only";
  icon?: React.ElementType;
  text?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  ariaLabel?: string;
};

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  {
    id,
    variant = "primary",
    size = "md",
    onClick,
    icon: Icon,
    iconPosition,
    text,
    className,
    loading = false,
    disabled = false,
    type = "button",
    ariaLabel,
    ...rest
  },
  ref,
) {
  const commonClassNames =
    "relative inline-flex items-center justify-center font-medium focus:outline-none";

  const commonFocusRingClassNames =
    "focus:ring-2 focus:ring-primary-600 focus:ring-inset";
  const sizeClassNames = match({ size, iconPosition })
    .with({ size: "sm", iconPosition: "only" }, () => "rounded-2xl p-2")
    .with({ size: "md", iconPosition: "only" }, () => "rounded-2xl p-3")
    .with({ size: "lg", iconPosition: "only" }, () => "rounded-2xl p-4")
    .with({ size: "sm" }, () => "rounded-2xl text-sm leading-none px-3.5 py-2")
    .with({ size: "md" }, () => "rounded-xl text-base leading-normal px-6 py-3")
    .with({ size: "lg" }, () => "rounded-lg text-xl leading-7 px-8 py-3")
    .exhaustive();

  const variantClassNames = match({ variant, disabled, iconPosition })
    .with(
      { variant: "tertiary", disabled: true, iconPosition: "only" },
      () => "fill-black dark:fill-white",
    )
    .with(
      { variant: "tertiary", disabled: true },
      () => "text-greyscale-300 fill-greyscale-600 dark:fill-greyscale-500",
    )
    .with(
      { disabled: true },
      () =>
        "text-gray-700 fill-greyscale-600 dark:text-greyscale-300 dark:fill-greyscale-500",
    )
    .with(
      { variant: "primary" },
      () =>
        "bg-primary-600 text-greyscale-0 hover:bg-primary-700 active:bg-primary-800 dark:bg-primary-800 dark:hover:bg-primary-700 dark:active:bg-primary-600",
    )
    .with(
      { variant: "secondary" },
      () =>
        "ring-1 ring-greyscale-200 ring-inset text-greyscale-900 dark:ring-greyscale-700 dark:text-greyscale-100 hover:ring-greyscale-400 active:ring-greyscale-700 dark:active:ring-greyscale-300 dark:hover:ring-greyscale-500",
    )
    .with(
      { variant: "tertiary" },
      () =>
        "text-primary-700 hover:text-primary-600 active:text-primary-800 dark:text-primary-300 dark:hover:text-primary-300 dark:active:text-primary-400",
    )
    .with(
      { variant: "critical" },
      () =>
        "ring-1 ring-red-500 ring-inset text-red-800 shadow-sm hover:bg-critical-100 active:bg-red-100  dark:ring-critical-600 dark:text-critical-400 dark:hover:bg-critical-800 dark:active:bg-critical-900",
    )
    .with(
      { variant: "quaternary" },
      () =>
        "text-greyscale-600 hover:text-greyscale-900 dark:ring-greyscale-700 dark:text-greyscale-100 ring-inset hover:ring-1 hover:ring-greyscale-400 active:ring-greyscale-700 dark:active:ring-greyscale-300 dark:hover:ring-greyscale-500",
    )
    .exhaustive();

  const leftIconClassName = match(size)
    .with("sm", () => `${text && Icon ? "-ml-1 mr-1" : ""}  h-4 w-4`)
    .with("md", () => `${text && Icon ? "-ml-3 mr-2" : ""} h-6 w-6`)
    .with("lg", () => `${text && Icon ? "-ml-5 mr-2" : ""} h-6 w-6`)
    .exhaustive();

  const rightIconClassName = match(size)
    .with("sm", () => `${text && Icon ? "-mr-1 ml-1" : ""} h-4 w-4`)
    .with("md", () => `${text && Icon ? "-mr-3 ml-2" : ""} h-6 w-6`)
    .with("lg", () => `${text && Icon ? "-mr-5 ml-2" : ""} h-6 w-6`)
    .exhaustive();

  const onlyIconClassName = match(size)
    .with("sm", () => "h-4 w-4")
    .with("md", () => "h-6 w-6")
    .with("lg", () => "h-8 w-8")
    .exhaustive();

  return (
    <button
      id={id}
      type={type}
      ref={ref}
      className={classNames(
        commonClassNames,
        sizeClassNames,
        variantClassNames,
        { [commonFocusRingClassNames]: !disabled },
        className,
      )}
      onClick={onClick}
      disabled={disabled || loading}
      aria-label={ariaLabel}
      {...rest}
    >
      {loading && (
        <LoadingBounce
          className={classNames(
            { [leftIconClassName]: !Icon && iconPosition === "left" },
            { [rightIconClassName]: !Icon && iconPosition === "right" },
            { absolute: !Icon || iconPosition === "only" },
          )}
        />
      )}
      {!loading && Icon && iconPosition === "left" && (
        <Icon className={leftIconClassName} />
      )}{" "}
      {/* Use LeftIcon here */}
      {iconPosition === "only" && Icon ? (
        <Icon className={classNames(onlyIconClassName)} />
      ) : (
        text && (
          <span
            className={classNames(
              { "text-transparent": loading && !Icon },
              "whitespace-pre",
            )}
          >
            {text}
          </span>
        )
      )}
      {!loading && Icon && iconPosition === "right" && (
        <Icon className={rightIconClassName} />
      )}{" "}
      {/* Use RightIcon here */}
    </button>
  );
});
