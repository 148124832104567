import { NameOrder, Viewer } from "../api/generated/graphql";
import {
  Chat as StreamChatProvider,
  useCreateChatClient,
} from "stream-chat-react";
import { StreamVideoClient } from "@stream-io/video-react-sdk";
import { EventHandler, StreamChat } from "stream-chat";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useSortingOptions } from "../utils/hooks/useSortingOptions";
import { useCommsContext } from "./CommsProvider";
import { match, P } from "ts-pattern";

type ChatProvider = {
  chatClient: StreamChat | null;
  videoClient: StreamVideoClient | null;
};

const ChatContext = createContext<ChatProvider>({
  chatClient: null,
  videoClient: null,
});

type ChatProviderProps = {
  viewer: Pick<Viewer, "id" | "chatToken" | "firstName" | "lastName">;
  nameOrder: NameOrder;
  country: string;
  darkMode?: boolean;
  children: React.ReactNode;
};

const ChatProvider = ({
  viewer,
  nameOrder,
  country,
  darkMode,
  children,
}: ChatProviderProps) => {
  const apiKey = import.meta.env.VITE_STREAM_KEY;
  const { nameOrderFn } = useSortingOptions({
    nameOrder,
    country,
  });
  const chatClient = useCreateChatClient({
    apiKey,
    tokenOrProvider: viewer.chatToken,
    userData: { id: viewer.id, name: nameOrderFn(viewer) },
  });

  const { updateUnreadCount } = useCommsContext();

  useEffect(() => {
    if (!chatClient) return;
    // Get the initial unread count
    chatClient
      .getUnreadCount()
      .then((value) => updateUnreadCount(value.total_unread_count));

    const handleChatEvent: EventHandler = (event) => {
      if (event.total_unread_count !== undefined) {
        updateUnreadCount(event.total_unread_count);
      }
    };
    chatClient.on(handleChatEvent);
    return () => chatClient.off(handleChatEvent);
  }, [chatClient, updateUnreadCount]);

  return (
    <ChatContext.Provider
      value={{
        chatClient,
        videoClient: null,
      }}
    >
      {match({ chatClient })
        .with({ chatClient: P.nonNullable }, ({ chatClient }) => {
          return (
            <StreamChatProvider
              client={chatClient}
              theme={darkMode ? "str-chat__theme-dark" : ""}
            >
              {children}
            </StreamChatProvider>
          );
        })
        .otherwise(() => children)}
    </ChatContext.Provider>
  );
};

function useChatContext() {
  return useContext(ChatContext);
}

export { useChatContext, ChatProvider };
