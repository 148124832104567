import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import {
  MessageErrorIcon,
  MessageDeleted as DefaultMessageDeleted,
  MessageText,
  MessageTimestamp as DefaultMessageTimestamp,
  areMessageUIPropsEqual,
  messageHasAttachments,
  messageHasReactions,
  EditMessageForm as DefaultEditMessageForm,
  MessageInput,
  MML,
  Modal,
  useComponentContext,
  MessageContextValue,
  useMessageContext,
  MessageUIComponentProps,
  DefaultStreamChatGenerics,
  MessageOptions,
  Attachment,
  useChannelStateContext,
} from "stream-chat-react";
import { ReactionsList } from "./ReactionsList";
import { Avatar } from "../avatar";
import { useUsersByIdsQuery } from "../api/generated/graphql";

import { nullable } from "./CommsChannelPreview";
import { Paragraph } from "../typography";

import { randomColorBasedOnName } from "../avatar/Avatar";
import { match } from "ts-pattern";
import { useTranslate } from "@tolgee/react";

type MessageSimpleWithContextProps<
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
> = MessageContextValue<StreamChatGenerics>;

const MessageSimpleWithContext = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>,
) => {
  const [showRead, setShowRead] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleRetry,
    highlighted,
    isMyMessage,
    message,
    renderText,
  } = props;
  const { t } = useTranslate();
  const { read, channel } = useChannelStateContext();
  // Identify any users that saw this message last (excluding current user)
  const lastReadUsers = Object.values(read ?? {})
    .filter(({ user }) => user.id !== channel._client.user?.id)
    .filter(({ last_read_message_id }) => last_read_message_id === message.id)
    .map(({ user }) => user);
  // All other members of the chat (excluding current user)
  const otherMembers = Object.values(channel.state.members).filter(
    ({ user_id }) => user_id !== channel._client.user?.id,
  );
  const readByEveryone = otherMembers.length === lastReadUsers.length;

  const {
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageTimestamp = DefaultMessageTimestamp,
  } = useComponentContext<StreamChatGenerics>("MessageSimple");

  const { data } = useUsersByIdsQuery({
    fetchPolicy: "cache-first",
    variables: {
      ids: [message.user?.id, ...lastReadUsers.map((user) => user.id)].filter(
        nullable,
      ),
    },
  });

  const messageUser = data?.usersByIds.find(
    ({ id }) => id === message.user?.id,
  );
  const firstName = messageUser?.firstName;
  const lastName = messageUser?.lastName;

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  // add click event listener on div so if clicked outside it turns
  // off the "showRead" value
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof Node)) return;
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowRead(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />;
  }

  const canShowReactions = hasReactions;

  const allowRetry =
    message.status === "failed" && message.errorStatusCode !== 403;

  const rootClassName = classNames(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage()
      ? "str-chat__message--me str-chat__message-simple--me"
      : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "pinned-message": message.pinned,
      "str-chat__message--has-attachment": hasAttachment,
      "str-chat__message--highlighted": highlighted,
      "str-chat__message--with-reactions str-chat__message-with-thread-link":
        canShowReactions,
      "str-chat__message-send-can-be-retried":
        message?.status === "failed" && message?.errorStatusCode !== 403,
      "str-chat__virtual-message__wrapper--end": endOfGroup,
      "str-chat__virtual-message__wrapper--first": firstOfGroup,
      "str-chat__virtual-message__wrapper--group": groupedByUser,
      "h-16": hasReactions,
    },
  );

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            hideSendButton
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div
          ref={divRef}
          className={rootClassName}
          key={message.id}
          onClick={() => setShowRead((current) => !current)}
        >
          {message.user && !isMyMessage() && (
            <Avatar
              firstName={firstName ?? ""}
              lastName={lastName ?? ""}
              randomizeColor
            />
          )}
          <div
            className={classNames("str-chat__message-inner", {
              "str-chat__simple-message--error-failed": allowRetry,
            })}
            data-testid="message-inner"
            onClick={allowRetry ? () => handleRetry(message) : undefined}
            onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
          >
            <MessageOptions />
            <div className="str-chat__message-bubble">
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment
                  actionHandler={handleAction}
                  attachments={message.attachments}
                />
              ) : null}
              <MessageText message={message} renderText={renderText} />
              <div className="str-chat__message-reactions-host">
                {canShowReactions && (
                  <ReactionsList
                    reverse
                    align={isMyMessage() ? "left" : "right"}
                  />
                )}
              </div>
              {message.mml && (
                <MML
                  actionHandler={handleAction}
                  align={isMyMessage() ? "right" : "left"}
                  source={message.mml}
                />
              )}
              <MessageErrorIcon />
            </div>
          </div>
          <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
            <MessageTimestamp
              calendar={false}
              format={"hh:mm:ss A"}
              customClass={classNames(
                "str-chat__message-simple-timestamp text-greyscale-600 text-xs font-inclusive",
                {
                  "pt-4": hasReactions,
                },
              )}
            />
          </div>
        </div>
      }
      {lastReadUsers.length > 0 && (
        <div className="flex flex-col place-self-end items-end gap-3">
          {match({ showRead, readByEveryone })
            .with({ showRead: true, readByEveryone: true }, () => (
              <Paragraph size="xxs" className="text-greyscale-400">
                {t("comms.seenByEveryone")}
              </Paragraph>
            ))
            .with({ showRead: true, readByEveryone: false }, () => (
              <Paragraph size="xxs" className="text-greyscale-400">
                {`${t("comms.seenBy")} ${lastReadUsers
                  .map((user) => user.name)
                  .filter(nullable)
                  .join(", ")}`}
              </Paragraph>
            ))
            .otherwise(() => null)}
          <div className="flex gap-1">
            {lastReadUsers.map((userMessage, index) => {
              const user = data?.usersByIds.find(
                ({ id }) => id === userMessage.id,
              );
              const firstName = user?.firstName ?? "";
              const lastName = user?.lastName ?? "";
              const initials =
                firstName.charAt(0).toUpperCase() +
                  lastName.charAt(0).toUpperCase() || "-";
              const { light, dark } = randomColorBasedOnName(initials);
              return (
                <div
                  key={user?.id ?? index}
                  className={classNames(
                    "flex items-center justify-center w-5 h-5 bg-greyscale-200 rounded-full",
                    light,
                    dark ? `dark:${dark}` : undefined,
                  )}
                >
                  <p className="text-greyscale-900 text-[10px] leading-[16px]">
                    {initials}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual,
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const MessageSimple = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: MessageUIComponentProps<StreamChatGenerics>,
) => {
  const messageContext = useMessageContext<StreamChatGenerics>("MessageSimple");

  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
