import { useFeatureFlag } from "../../providers/FeatureFlags";
import { Signature, SignatureRef } from "@frontend/lyng/signatures/Signature";
import { LoadingBounce } from "@frontend/lyng/loading";
import { useNavigate, useParams, useRevalidator } from "react-router";
import {
  useSignatureUploadMutation,
  useVisitByIdQuery,
} from "../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { useRef, useState } from "react";
import { Button } from "@frontend/lyng/button";
import ImportantInfo from "../../assets/important-info.svg?react";
import { Title } from "./Title";
import classNames from "classnames";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";
interface Props {
  user: string;
}

export const UserSignature = ({ user }: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const ff_signatures = useFeatureFlag("Signatures");
  const userSignatureRef = useRef<SignatureRef>(null);
  const isCaregiver = user === "caregiver";
  const [SignatureUploadMutation, { loading: loadingUrl }] =
    useSignatureUploadMutation({});
  const [uploading, setUploading] = useState<boolean>(false);
  const revalidator = useRevalidator();

  const Loading = () => (
    <div className="flex justify-center dark:bg-greyscale-900 m-4">
      <LoadingBounce size="m" />
    </div>
  );

  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });

  if (!ff_signatures) {
    navigate("/");
    return <Loading />;
  }

  if (!data?.visitById) {
    return <span>No visit data</span>;
  }

  if (loading) {
    return <span>Loading visit data</span>;
  }

  if (error) {
    return <span>Error loading visit data</span>;
  }

  const handleClearSignature = (ref: SignatureRef | null) => {
    if (ref) {
      ref.clear();
    }
  };

  const handleSaveSignature = async (ref: SignatureRef | null) => {
    if (ref) {
      const savedSignature = ref.getSavedSignature();
      let signedBy;
      if (savedSignature) {
        if (!isCaregiver) {
          signedBy = data?.visitById?.careRecipient.id;
        } else if (isCaregiver) {
          signedBy = data?.visitById?.visitorIds?.[0];
        }
      }

      if (!signedBy || !id) return;

      try {
        const res = await SignatureUploadMutation({
          variables: {
            input: { signedBy, visitInstanceId: id },
          },
          refetchQueries: ["VisitById"],
        });
        const uploadUrl = res.data?.signatureUpload;
        if (uploadUrl && savedSignature) {
          await uploadSignature(uploadUrl, savedSignature);
          navigate({
            pathname: `/visit/${id}/signature`,
          });
        } else {
          console.error("No upload URL received.");
        }
      } catch (error) {
        console.error("Signature upload failed:", error);
      }
    }
  };

  const uploadSignature = async (url: string, signature: string) => {
    setUploading(true);
    // For decoding the signature, can't send base64 image
    const base64Data = signature.replace(/^data:image\/png;base64,/, "");
    const binaryData = atob(base64Data);
    const arrayBuffer = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: "image/png" });

    const res = await fetch(url, {
      method: "PUT",
      headers: { "Content-Type": "image/png" },
      body: blob,
    });

    if (!res.ok) {
      toast.error("Error uploading signature");
      Sentry.captureException(await res.text(), (scope) => {
        scope.setTransactionName("Error uploading signature");
        return scope;
      });
      return;
    }
    revalidator.revalidate();

    // Clear states
    setUploading(false);
  };

  const name = data.visitById.careRecipient.firstName;

  return (
    <div className="flex-col pb-10">
      <div className="flex grow flex-col overflow-hidden w-full">
        {!isCaregiver && (
          <div className="absolute top-10 pl-20 justify-center">
            <Title name={name} />
          </div>
        )}
        <div className="row flex items-center px-4 py-4 bg-primary-900 rounded-xl m-4 md:hidden">
          <ImportantInfo className="h-8 w-8" />
          <div className="flex grow flex-col pl-4 text-medium text-title">
            <div>{t("signatures.importantInformation")}</div>
          </div>
        </div>
        <div
          className={classNames(
            "pb-2 md:pb-0 pl-5 pt-2 text-xl text-title",
            isCaregiver ? "-mt-2 " : "",
          )}
        >
          {t("signatures.signHere")}
        </div>
        <Signature
          isEditing={true}
          ref={userSignatureRef}
          readOnly={false}
          boxWidth="w-100"
          boxHeight="h-[60vh] md:h-[50vh]"
        />
        <div className="flex grow flex-row justify-around">
          <Button
            variant="secondary"
            text={t("clear")}
            className="justify-center flex grow mx-3"
            onClick={() => handleClearSignature(userSignatureRef.current)}
          />
          <Button
            text={t("save")}
            className="justify-center flex grow mx-3"
            onClick={() => {
              handleSaveSignature(userSignatureRef.current);
            }}
            loading={loadingUrl || uploading}
          />
        </div>
      </div>
    </div>
  );
};
