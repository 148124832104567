import { Calendar as CalendarIcon, Close } from "../../assets/icons/16/outline";
import {
  CalendarDate,
  CalendarDateTime,
  createCalendar,
} from "@internationalized/date";
import { AriaButtonOptions, useButton } from "@react-aria/button";
import {
  AriaDateFieldOptions,
  AriaDateRangePickerProps,
  useDateField,
  useDatePicker,
  useDateRangePicker,
  useDateSegment,
} from "@react-aria/datepicker";
import { useDialog } from "@react-aria/dialog";
import { DismissButton, Overlay, usePopover } from "@react-aria/overlays";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useRef } from "react";
import {
  DateFieldState,
  DatePickerStateOptions,
  DateSegment,
  useDateFieldState,
  useDatePickerState,
  useDateRangePickerState,
} from "react-stately";
import { match } from "ts-pattern";
import { Calendar, RangeCalendar } from "./Calendar";
import { DateFormat } from "../../api/generated/graphql";
import { Paragraph } from "../../typography";
import {
  DatePickerProps,
  DateRangePickerProps,
  DialogProps,
  PopoverProps,
  WithOrWithoutTime,
} from "./types";
import { DateSettings, useDateFormatter } from "../../utils/dateUtils";
import { ErrorMessage } from "../errorMessage/ErrorMessage";

export function dateTimeToCalendarDate<TWithTime extends boolean>(
  date: DateTime,
  withTime: TWithTime,
): WithOrWithoutTime<TWithTime> {
  if (withTime) {
    return new CalendarDateTime(
      date.year,
      date.month,
      date.day,
      date.hour,
      date.minute,
    ) as WithOrWithoutTime<TWithTime>;
  }
  return new CalendarDate(
    date.year,
    date.month,
    date.day,
  ) as WithOrWithoutTime<TWithTime>;
}
export function calendarDateToDateTime<TWithTime extends boolean>(
  date: WithOrWithoutTime<TWithTime>,
): DateTime {
  if (date instanceof CalendarDateTime) {
    return DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: date.hour,
      minute: date.minute,
    });
  }
  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
  });
}

const getFieldWrapperClassName = (
  isDisabled: boolean,
  isInvalid: boolean,
  displayView: boolean,
  isOpen: boolean,
  showCalendarButton: boolean,
) => {
  return classNames(
    "h-12 w-full pl-4 pr-8 py-2.5 rounded-l-xl border-y border-l text-lg placeholder-greyscale-600 placeholder:font-inclusive text-greyscale-900 dark:text-greyscale-100 dark:placeholder-greyscale-300 transition-colors",
    {
      "border-greyscale-200 group-hover:border-greyscale-400 group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600 dark:border-greyscale-700 dark:group-hover:border-greyscale-500 dark:group-focus-within:border-primary-600 dark:group-focus-within:group-hover:border-primary-600":
        !isInvalid && !displayView && !isDisabled,
      "border-critical-600 pr-10 focus:border-primary-600":
        isInvalid && !displayView && !isDisabled,
      "border-transparent bg-transparent hover:border-transparent": displayView,
      "bg-transparent group-hover:bg-greyscale-0 group-focus-within:bg-greyscale-0 dark:bg-greyscale-800 dark:group-hover:bg-greyscale-900 dark:group-focus-within:bg-greyscale-900":
        !displayView && !isOpen,
      "rounded-r-xl border-r": !showCalendarButton,
      "border-greyscale-200": isDisabled,
      "bg-greyscale-0 border-primary-600 dark:border-primary-600 dark:bg-greyscale-900":
        isOpen,
    },
  );
};

export function DatePicker<TWithTime extends boolean = false>(
  props: DatePickerProps<TWithTime>,
) {
  const { meridiem } = useDateFormatter(props.dateSettings);

  const {
    value,
    onChange,
    minValue,
    maxValue,
    showCalendar = "showCalendarButton",
    withTime = false as TWithTime,
    isClearable = false,
    dateSettings,
    errorMessage,
    displayView = false,
    ...otherProps
  } = props;

  const innerValue = value
    ? dateTimeToCalendarDate<TWithTime>(value, withTime)
    : null;
  function innerOnChange<T extends boolean>(
    value: WithOrWithoutTime<T> | undefined,
  ) {
    if (value instanceof CalendarDateTime) {
      return onChange && onChange(value ? calendarDateToDateTime(value) : null);
    }
    return onChange && onChange(value ? calendarDateToDateTime(value) : null);
  }
  const innerMinValue = minValue
    ? dateTimeToCalendarDate(minValue, withTime)
    : undefined;
  const innerMaxValue = maxValue
    ? dateTimeToCalendarDate(maxValue, withTime)
    : undefined;

  const innerProps: DatePickerStateOptions<WithOrWithoutTime<TWithTime>> = {
    ...otherProps,
    value: innerValue,
    onChange: innerOnChange,
    minValue: innerMinValue,
    maxValue: innerMaxValue,
    isDisabled: props.isDisabled || displayView,
    hourCycle: meridiem ? 12 : 24,
    isInvalid: !!props.errorMessage,
  };
  const state = useDatePickerState(innerProps);
  const ref = useRef(null);
  const { groupProps, fieldProps, buttonProps, dialogProps, calendarProps } =
    useDatePicker(innerProps, state, ref);

  if (displayView && !value) {
    return (
      <Paragraph size="m" className="flex h-12 items-center px-5">
        -
      </Paragraph>
    );
  }

  // TODO: Change this to be on focus rather than on click
  const onClick = () => {
    if (props.showCalendar === "showCalendarOnFocus") {
      state.open();
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className={classNames("relative flex", props.className)}>
        <div {...groupProps} ref={ref} className="group flex w-full">
          <div
            className={getFieldWrapperClassName(
              !!props.isDisabled,
              state.isInvalid,
              displayView,
              state.isOpen,
              showCalendar === "showCalendarButton",
            )}
            onClick={onClick}
          >
            <DateField
              {...fieldProps}
              editView={!displayView}
              onClick={onClick}
              dateSettings={dateSettings}
            />
            {isClearable && !!value && !displayView ? (
              <button
                className={classNames(
                  "absolute inset-y-0 flex items-center mr-3 focus:outline-none",
                  {
                    "right-8": showCalendar === "showCalendarButton",
                    "right-0": showCalendar !== "showCalendarButton",
                  },
                )}
                onClick={() => onChange && onChange(null)}
              >
                <Close className="text-greyscale-600 dark:text-critical-500" />
              </button>
            ) : null}
          </div>
          {showCalendar === "showCalendarButton" && !displayView && (
            <DatePickerButton
              {...buttonProps}
              isDisabled={props.isDisabled}
              isPressed={state.isOpen}
              isInvalid={state.isInvalid}
              isOpen={state.isOpen}
            />
          )}
        </div>
        {state.isOpen && (
          <Popover triggerRef={ref} state={state} placement="bottom start">
            <Dialog {...dialogProps}>
              <Calendar
                {...calendarProps}
                country={props.dateSettings?.country ?? ""}
              />
            </Dialog>
          </Popover>
        )}
      </div>
      {errorMessage && !displayView && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
}

export function DateRangePicker(props: DateRangePickerProps) {
  const {
    value,
    onChange,
    isClearable = false,
    minValue,
    maxValue,
    dateSettings,
    errorMessage,
    displayView = false,
    ...otherProps
  } = props;

  const innerValue = value
    ? {
        start: dateTimeToCalendarDate(value.start, false),
        end: dateTimeToCalendarDate(value.end, false),
      }
    : undefined;
  const innerOnChange = (
    value: { start: CalendarDate; end: CalendarDate } | undefined,
  ) =>
    onChange &&
    onChange(
      value
        ? {
            start: calendarDateToDateTime(value.start),
            end: calendarDateToDateTime(value.end),
          }
        : undefined,
    );
  const innerMinValue = minValue
    ? dateTimeToCalendarDate(minValue, false)
    : undefined;
  const innerMaxValue = maxValue
    ? dateTimeToCalendarDate(maxValue, false)
    : undefined;

  const innerProps: AriaDateRangePickerProps<CalendarDate> = {
    ...otherProps,
    value: innerValue,
    onChange: innerOnChange,
    minValue: innerMinValue,
    maxValue: innerMaxValue,
    isDisabled: props.isDisabled || displayView,
    isInvalid: !!errorMessage,
  };

  const state = useDateRangePickerState(innerProps);
  const ref = useRef(null);
  const {
    groupProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(innerProps, state, ref);

  const showCalendar = "showCalendarButton";

  if (displayView && !value) {
    return (
      <Paragraph size="m" className="flex h-12 items-center px-5">
        -
      </Paragraph>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="relative flex">
        <div {...groupProps} ref={ref} className="group flex w-full">
          <div
            className={classNames(
              "flex",
              "h-12 w-full pl-4 pr-8 py-2.5 rounded-l-xl border-y border-l text-lg placeholder-greyscale-600 placeholder:font-inclusive text-greyscale-900 dark:text-greyscale-100 dark:placeholder-greyscale-300 transition-colors",
              {
                "border-greyscale-200 group-hover:border-greyscale-400 group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600 dark:border-greyscale-700 dark:group-hover:border-greyscale-500 dark:group-focus-within:border-primary-600 dark:group-focus-within:group-hover:border-primary-600":
                  !state.isInvalid && !displayView && !props.isDisabled,
                "border-critical-600 pr-10 focus:border-primary-600":
                  state.isInvalid && !displayView && !props.isDisabled,
                "border-transparent bg-transparent hover:border-transparent":
                  displayView,
                "bg-transparent group-hover:bg-greyscale-0 group-focus-within:bg-greyscale-0 dark:bg-greyscale-800 dark:group-hover:bg-greyscale-900 dark:group-focus-within:bg-greyscale-900":
                  !displayView && !state.isOpen,
                "rounded-r-xl border-r": showCalendar !== "showCalendarButton",
                "border-greyscale-200": props.isDisabled,
                "bg-greyscale-0 border-primary-600 dark:border-primary-600 dark:bg-greyscale-900":
                  state.isOpen,
              },
            )}
          >
            <DateField
              {...startFieldProps}
              editView={!displayView}
              onClick={props.onClick}
              dateSettings={dateSettings}
            />
            <span aria-hidden="true" className="px-2">
              –
            </span>
            <DateField
              {...endFieldProps}
              editView={!displayView}
              onClick={props.onClick}
              dateSettings={dateSettings}
            />
            {isClearable && !!value && !displayView ? (
              <button
                className="absolute inset-y-0 right-8 flex items-center mr-3 focus:outline-none"
                onClick={() => onChange && onChange(undefined)}
              >
                <Close className="text-greyscale-600 dark:text-critical-500" />
              </button>
            ) : null}
          </div>
          {!displayView && (
            <DatePickerButton
              {...buttonProps}
              isDisabled={props.isDisabled}
              isPressed={state.isOpen}
              isInvalid={state.isInvalid}
              isOpen={state.isOpen}
            />
          )}
        </div>
        {state.isOpen && (
          <Popover triggerRef={ref} state={state} placement="bottom start">
            <Dialog {...dialogProps}>
              <RangeCalendar
                {...calendarProps}
                country={props.dateSettings?.country ?? ""}
              />
            </Dialog>
          </Popover>
        )}
      </div>
      {errorMessage && !displayView && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
}

export const getLocale = (settings: DateSettings) => {
  if (!settings) {
    return "en-US";
  }

  switch (settings.dateFormat) {
    case DateFormat.DdMmYyyy:
      return "en-GB";
    case DateFormat.MmDdYyyy:
      return "en-US";
  }
};

export function DateField({
  editView = true,
  dateSettings,
  onClick,
  ...props
}: AriaDateFieldOptions<CalendarDate> & {
  editView?: boolean;
  dateSettings: DateSettings | undefined;
  onClick?: () => void;
}) {
  const locale = getLocale(dateSettings);

  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className="flex">
      {state.segments.map((segment, i) => (
        <Segment
          key={i}
          segment={segment}
          state={state}
          editView={editView}
          onClick={onClick}
        />
      ))}
    </div>
  );
}

export function Segment({
  segment,
  state,
  editView = true,
  onClick,
}: {
  segment: DateSegment;
  state: DateFieldState;
  editView?: boolean;
  onClick?: () => void;
}) {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={segmentProps.style}
      onClick={onClick}
      className={classNames(
        "min group box-content rounded-sm px-0.5 text-right text-lg tabular-nums outline-none focus:bg-primary-500 focus:text-white",
        editView && (!segment.isEditable || state.isDisabled)
          ? "text-greyscale-500"
          : "text-greyscale-900 dark:text-greyscale-100",
        editView && segment.maxValue
          ? `min-w-[${String(segment.maxValue).length}ch]`
          : "",
      )}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. Hide placeholder in display view to preserve layout alignments */}
      {editView && (
        <span
          aria-hidden="true"
          className={classNames(
            "pointer-events-none block w-full text-center italic text-gray-500 group-focus:text-white dark:text-greyscale-200",
            {
              invisible: !segment.isPlaceholder,
              "h-0": !segment.isPlaceholder,
            },
          )}
          onClick={onClick}
        >
          {segment.placeholder}
        </span>
      )}
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
}

export function DatePickerButton(
  props: AriaButtonOptions<"button"> & {
    isPressed: boolean;
    isInvalid?: boolean;
    isOpen: boolean;
  },
) {
  const ref = useRef(null);
  const { buttonProps, isPressed } = useButton(props, ref);

  const colorClassName = match({
    isDisabled: !!props.isDisabled,
    isInvalid: !!props.isInvalid,
    isActive: props.isOpen,
  })
    .with(
      { isDisabled: true },
      () => "border-greyscale-200 bg-gray-50 text-gray-500",
    )
    .with(
      { isDisabled: false, isActive: true },
      () =>
        "bg-greyscale-0 border-primary-600 dark:bg-greyscale-900 dark:border-primary-600",
    )
    .with(
      { isDisabled: false, isInvalid: true },
      () =>
        "bg-transparent group-hover:bg-greyscale-0 group-focus-within:bg-greyscale-0 dark:bg-greyscale-transparent dark:group-hover:bg-greyscale-900 dark:group-focus-within:bg-greyscale-900 border-critical-600 group-focus-within:border-critical-600 group-focus-within:outline-none",
    )
    .with(
      { isDisabled: false, isInvalid: false, isActive: false },
      () =>
        `bg-transparent group-hover:bg-greyscale-0 group-focus-within:bg-greyscale-0 dark:bg-greyscale-transparent dark:group-hover:bg-greyscale-900 dark:group-focus-within:bg-greyscale-900
         border-greyscale-200 group-hover:border-greyscale-400 group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600 dark:border-greyscale-700 dark:group-hover:border-greyscale-500 dark:group-focus-within:border-primary-600 dark:group-focus-within:group-hover:border-primary-600`,
    )
    .exhaustive();

  const iconColorClassName = match({
    isDisabled: !!props.isDisabled,
    isInvalid: !!props.isInvalid,
    isOpen: props.isOpen,
    isPressed: isPressed || props.isPressed,
  })
    .with({ isDisabled: true }, () => "text-gray-400")
    .with(
      { isOpen: true },
      { isPressed: true },
      () => "text-primary-600 dark:text-primary-600",
    )
    .with({ isInvalid: true }, () => "text-critical-800 dark:text-critical-400")
    .with(
      { isDisabled: false, isInvalid: false, isOpen: false },
      () =>
        "text-greyscale-600 group-hover:text-greyscale-900 dark:text-greyscale-500 dark:group-hover:text-greyscale-0",
    )
    .exhaustive();

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={classNames(
        "-ml-px h-full rounded-r-xl border-y border-r pl-3 pr-4 outline-none transition-colors disabled:cursor-not-allowed",
        colorClassName,
      )}
    >
      <CalendarIcon
        className={classNames("h-5 w-5 transition-colors", iconColorClassName)}
      />
    </button>
  );
}

export function Dialog({ children, ...props }: DialogProps) {
  const ref = useRef(null);
  const { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  );
}

export function Popover(props: PopoverProps) {
  const ref = useRef(null);
  const { state, children } = props;

  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
    },
    state,
  );

  return (
    <Overlay>
      <div {...underlayProps} className="fixed inset-0" />
      <div
        {...popoverProps}
        ref={ref}
        className="absolute z-10 my-2 overflow-auto"
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}
