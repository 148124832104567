import classNames from "classnames";
import {
  HighPriority,
  LowPriority,
  MediumPriority,
} from "../assets/icons/12/outline";

type Props = {
  priority: "LOW" | "MEDIUM" | "HIGH";
  className?: string;
};

export const PriorityIcon = ({ priority }: Props) => {
  if (priority === "HIGH") {
    return (
      <HighPriority
        className={classNames("text-secondary-500 dark:text-[#8975DE]")}
      />
    );
  } else if (priority === "MEDIUM") {
    return (
      <MediumPriority className="text-secondary-500 dark:text-[#8975DE] [&>rect:last-child]:fill-greyscale-100 dark:[&>rect:last-child]:fill-greyscale-800" />
    );
  } else {
    return (
      <LowPriority className="text-secondary-500 dark:text-[#8975DE] [&>rect:nth-child(2)]:fill-greyscale-100 [&>rect:last-child]:fill-greyscale-100 dark:[&>rect:nth-child(2)]:fill-greyscale-800 dark:[&>rect:last-child]:fill-greyscale-800" />
    );
  }
};
