import classNames from "classnames";
import { match } from "ts-pattern";

type lightDark = { light: string; dark?: string };
type colors = {
  blue: lightDark;
  green: lightDark;
  red: lightDark;
  yellow: lightDark;
};
const randomizedColors: colors = {
  blue: { light: "bg-primary-300", dark: "bg-primary-400" },
  green: { light: "bg-secondary-300", dark: "bg-secondary-500" },
  red: { light: "bg-critical-200", dark: "bg-critical-400" },
  yellow: { light: "bg-accent-400", dark: "bg-accent-400" },
} as const;
type coloKey = keyof typeof randomizedColors;
const colorKeys = Object.keys(randomizedColors) as coloKey[];

export const randomColorBasedOnName = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = (hash * 31 + name.charCodeAt(i)) & 0xffffffff; // Simple hash function
  }

  const index = Math.abs(hash % colorKeys.length);
  const color = colorKeys[index];
  return randomizedColors[color];
};

const mapColor = (value: string | lightDark): lightDark => {
  if (typeof value === "string") return { light: value, dark: value };
  return value;
};

type Props = {
  firstName?: string;
  lastName?: string;
  img?: string;
  backgroundColor?: string | lightDark;
  size?: "sm" | "md" | "lg";
  randomizeColor?: boolean;
  className?: string;
};

export const Avatar = ({
  firstName = "",
  lastName = "",
  img,
  backgroundColor = "bg-primary-90",
  size = "md",
  randomizeColor = false,
  className = "",
}: Props) => {
  const text =
    firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase() ||
    "-";

  const sizeStyles = match(size)
    .with("sm", () => "h-6 w-6")
    .with("md", () => "h-8 w-8")
    .with("lg", () => "h-10 w-10")
    .exhaustive();

  const fontStyles = match(size)
    .with("sm", () => "text-xs font-normal text-greyscale-900")
    .with("md", () => "text-sm font-medium text-greyscale-900")
    .with("lg", () => "text-base font-medium text-greyscale-900")
    .exhaustive();

  const { light, dark } = randomizeColor
    ? randomColorBasedOnName(text)
    : mapColor(backgroundColor);

  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center overflow-hidden rounded-full py-2",
        light,
        dark ? `dark:${dark}` : undefined,
        sizeStyles,
        className,
      )}
    >
      {img ? (
        <img
          src={img}
          alt={text}
          className="h-full w-full rounded-full object-cover"
        />
      ) : (
        <p className={fontStyles}>{text}</p>
      )}
    </div>
  );
};
