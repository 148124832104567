import { ChevronLeft, ChevronRight } from "../../assets/icons/24/outline";
import {
  CalendarDate,
  DateDuration,
  createCalendar,
  endOfMonth,
  getDayOfWeek,
  getWeeksInMonth,
  isSameDay,
} from "@internationalized/date";
import { AriaButtonOptions, useButton } from "@react-aria/button";
import {
  AriaCalendarGridProps,
  AriaCalendarProps,
  AriaRangeCalendarProps,
  DateValue,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useRangeCalendar,
} from "@react-aria/calendar";
import { useFocusRing } from "@react-aria/focus";
import { useLocale } from "@react-aria/i18n";
import merge from "lodash/merge";
import { useEffect, useRef } from "react";
import {
  CalendarState,
  RangeCalendarState,
  useCalendarState,
  useRangeCalendarState,
} from "react-stately";
import { Headline, Label } from "../../typography";
import classNames from "classnames";
import { DateTime } from "luxon";

export function Calendar(
  props: AriaCalendarProps<DateValue> & { country: string },
) {
  const { locale } = useLocale();

  const state = useCalendarState({
    ...props,
    locale,
    visibleDuration: { months: 1 },
    createCalendar,
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state);

  return (
    <div
      {...calendarProps}
      className="inline-block bg-greyscale-0 dark:bg-greyscale-800 rounded-xl border border-greyscale-200 dark:border-greyscale-700 shadow "
    >
      <div className="pl-6 pr-2 py-3 flex items-center justify-between">
        <Headline size="m">{title}</Headline>
        <div className="flex gap-2">
          <CalendarButton {...prevButtonProps}>
            <ChevronLeft className="h-4 w-4" />
          </CalendarButton>
          <CalendarButton {...nextButtonProps}>
            <ChevronRight className="h-4 w-4" />
          </CalendarButton>
        </div>
      </div>
      <CalendarGrid state={state} country={props.country} />
    </div>
  );
}
export function RangeCalendar(
  props: AriaRangeCalendarProps<DateValue> & { country: string },
) {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref);

  return (
    <div
      {...calendarProps}
      className="inline-block bg-greyscale-0 dark:bg-greyscale-800 rounded-xl border border-greyscale-200 dark:border-greyscale-700 shadow"
    >
      <div className="pl-6 pr-2 py-3 flex items-center justify-between">
        <Headline size="m">{title}</Headline>
        <div className="flex gap-2">
          <CalendarButton {...prevButtonProps}>
            <ChevronLeft className="h-4 w-4" />
          </CalendarButton>
          <CalendarButton {...nextButtonProps}>
            <ChevronRight className="h-4 w-4" />
          </CalendarButton>
        </div>
      </div>
      <CalendarGrid state={state} country={props.country} />
    </div>
  );
}

type CalendarButtonProps = AriaButtonOptions<"button"> & {
  isDisabled?: boolean;
  children: React.ReactNode;
};
function CalendarButton(props: CalendarButtonProps) {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...merge(buttonProps, focusProps)}
      ref={ref}
      className={classNames(
        "rounded-2xl p-2 inline-flex items-center justify-center focus:outline-none",
        "ring-1 ring-greyscale-200 ring-inset text-black dark:ring-greyscale-700 dark:text-greyscale-100 hover:ring-greyscale-400 active:ring-greyscale-700 dark:active:ring-greyscale-300 dark:hover:ring-greyscale-500",
        {
          "text-grayscale-700": props.isDisabled,
          "ring-2 ring-primary-600 ring-inset": isFocusVisible,
        },
      )}
    >
      {props.children}
    </button>
  );
}

type CalendarGridProps = Omit<
  AriaCalendarGridProps,
  "startDate" | "endDate"
> & {
  country: string;
  state: CalendarState | RangeCalendarState;
  offset?: DateDuration;
};

function CalendarGrid({
  state,
  offset = {},
  country,
  ...props
}: CalendarGridProps) {
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);

  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    { ...props, startDate, endDate },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, country ?? "");

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead
        {...headerProps}
        className="px-2 border-b border-b-greyscale-200 dark:border-greyscale-700"
      >
        <tr>
          {weekDays.map((day, index) => (
            <th className="text-center first:pl-2 last:pr-2" key={index}>
              <Label
                size="xs"
                variant="tertiary"
                className="inline-block px-3 py-1.5"
              >
                {day}
              </Label>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type CalendarCellProps = {
  state: CalendarState | RangeCalendarState;
  date: CalendarDate;
};
function CalendarCell({ state, date }: CalendarCellProps) {
  const { locale } = useLocale();

  const ref = useRef<HTMLDivElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart =
    "highlightedRange" in state && state.highlightedRange
      ? isSameDay(date, state.highlightedRange.start)
      : isSelected;
  const isSelectionEnd =
    "highlightedRange" in state && state.highlightedRange
      ? isSameDay(date, state.highlightedRange.end)
      : isSelected;

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date));
  const isToday =
    DateTime.now().day === date.day &&
    DateTime.now().month === date.month &&
    DateTime.now().year === date.year;

  const { focusProps, isFocusVisible } = useFocusRing();

  // Workaround to prevent touchstart leaking to underlying elements:
  // https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1172267250
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current?.addEventListener("touchstart", (event: TouchEvent) => {
      event.preventDefault();
    });
  }, []);

  return (
    <td
      {...cellProps}
      className={classNames("relative py-0.5 first:pl-2 last:pr-2", {
        "z-10": isFocusVisible,
        "z-0": !isFocusVisible,
        // Padding outside the rounded area for selection end so background is not included
        "pl-1.5 first:pl-3.5": isRoundedLeft,
        "pr-1.5 last:pr-3.5": isRoundedRight,
      })}
    >
      <div
        {...merge(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={classNames("group outline-none", {
          "rounded-l-full": isRoundedLeft,
          "rounded-r-full": isRoundedRight,
          // Padding inside background for selection start and end
          "pl-1.5": !isRoundedLeft,
          "pr-1.5": !isRoundedRight,
          // Background color for range selection between start and end
          "bg-critical-100 dark:bg-critical-900": isSelected && isInvalid,
          "bg-primary-100 dark:bg-primary-800": isSelected && !isInvalid,
        })}
      >
        <div
          className={classNames(
            "flex h-10 w-10 items-center justify-center rounded-full",
            {
              "ring-1 ring-primary-600": isToday,
              "text-greyscale-700 opacity-40": isDisabled && !isInvalid,
              // Focus ring, visible while the cell has keyboard focus.
              "group-focus:z-2 ring-2 ring-offset-2": isFocusVisible,
              "ring-primary-600": isFocusVisible && !isInvalid,
              "ring-critical-600": isFocusVisible && isInvalid,
              // Darker selection background for the start and end.
              "bg-critical-300 hover:bg-critical-400 dark:bg-critical-800 dark:hover:bg-critical-600":
                (isSelectionStart || isSelectionEnd) && isInvalid,
              "bg-primary-300 hover:bg-primary-400 dark:bg-primary-700 dark:hover:bg-primary-500":
                (isSelectionStart || isSelectionEnd) && !isInvalid,
              // Hover state for cells in the middle of the range.
              "hover:bg-critical-400 dark:hover:bg-critical-600":
                isSelected &&
                !isDisabled &&
                !(isSelectionStart || isSelectionEnd) &&
                isInvalid,
              "hover:bg-primary-400 dark:hover:bg-primary-500":
                isSelected &&
                !isDisabled &&
                !(isSelectionStart || isSelectionEnd) &&
                !isInvalid,
              // Hover state for non-selected cells.
              "hover:bg-primary-100 dark:hover:bg-primary-800":
                !isSelected && !isDisabled,
            },
          )}
        >
          <Label
            size="s"
            className={classNames("inline-block", {
              "text-primary-700": isToday,
            })}
            variant={isDisabled ? "tertiary" : "primary"}
          >
            {formattedDate}
          </Label>
        </div>
      </div>
    </td>
  );
}
