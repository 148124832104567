import classNames from "classnames";
import { match } from "ts-pattern";
import { NumberPillColors } from "./types";

type Props = {
  count: number;
  color: NumberPillColors;
  className?: string;
  capCount?: boolean;
};

export const NumberPill = ({
  count,
  color,
  className,
  capCount = true,
}: Props) => {
  const bgColor = match(color)
    .with("redSubdued", () => "bg-critical-200 dark:bg-critical-900")
    .with("red", () => "bg-critical-300 dark:bg-critical-800")
    .with("yellow", () => "bg-accent-400 dark:bg-accent-400")
    .with("gray", () => "bg-greyscale-100 dark:bg-greyscale-700")
    .with("green", () => "bg-secondary-200 dar:bg-secondary-700")
    .exhaustive();

  const textColor = match(color)
    .with("yellow", () => "text-greyscale-900")
    .with("green", () => "dark:text-greyscale-100")
    .otherwise(() => "text-greyscale-900 dark:text-greyscale-100");

  if (count === 0) return;

  return (
    <div
      className={classNames(
        "min-w-5 h-5 text-sm font-medium rounded-full flex justify-center items-center",
        bgColor,
        className,
        textColor,
      )}
    >
      {count > 99 && capCount ? "99+" : count}
    </div>
  );
};
