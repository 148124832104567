import classNames from "classnames";
import { useRef } from "react";
import {
  ChannelPreviewUIComponentProps,
  DefaultStreamChatGenerics,
  MessageDeliveryStatus,
  StreamMessage,
} from "stream-chat-react";
import { Checkmark } from "../assets/icons/16/outline";
import { Paragraph } from "../typography";
import { Avatar } from "../avatar";
import { UsersByIdsQuery, useUsersByIdsQuery } from "../api/generated/graphql";
import { NumberPill, Pill } from "../pill";
import { useParams } from "react-router";
import { Channel } from "stream-chat";
import { match, P } from "ts-pattern";
import { useTranslate } from "@tolgee/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const getLastMessageTime = (lastMessage?: StreamMessage) => {
  if (!lastMessage) return null;

  if (lastMessage.created_at) {
    const date =
      typeof lastMessage.created_at === "string"
        ? new Date(lastMessage.created_at)
        : (lastMessage.created_at as Date);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  return null;
};

type MessageDeliveryStatusIndicator = {
  messageDeliveryStatus: MessageDeliveryStatus;
};

const MessageDeliveryStatusIndicator = ({
  messageDeliveryStatus,
}: MessageDeliveryStatusIndicator) => {
  // the last message is not an own message in the channel
  if (!messageDeliveryStatus) return null;

  return (
    <div>
      {messageDeliveryStatus === MessageDeliveryStatus.DELIVERED && (
        <Checkmark className="text-primary-600" />
      )}
      {messageDeliveryStatus === MessageDeliveryStatus.READ && (
        <div className="text-primary-600 flex flex-row">
          <Checkmark />
          <Checkmark className="-ml-5" />
        </div>
      )}
    </div>
  );
};

export const ChatName = ({ users }: { users: UsersByIdsQuery | undefined }) => {
  const { t } = useTranslate();
  return match(users?.usersByIds ?? [])
    .with([P.select()], (user) => `${user.firstName} ${user.lastName}`)
    .with(
      [...P.array(P.any).select("users"), P.select("lastUser")],
      ({ users, lastUser }) => {
        return `${users.map(({ firstName }) => firstName).join(", ")} ${t("comms.and")} ${lastUser.firstName}`;
      },
    )
    .otherwise(() => "-");
};
const ChannelTitle = ({
  data,
  loading,
}: {
  data: UsersByIdsQuery | undefined;
  loading: boolean;
}) => {
  return (
    <Paragraph size="xs" className="text-greyscale-100 font-poppins truncate">
      {loading ? <Skeleton width={50} /> : <ChatName users={data} />}
    </Paragraph>
  );
};

export function nullable<T>(t: T | undefined | null): t is T {
  return t !== undefined && t !== null;
}

export const ChannelAvatar = ({
  data,
  loading,
  channel,
}: {
  data: UsersByIdsQuery | undefined;
  loading: boolean;
  channel: Channel<DefaultStreamChatGenerics>;
}) => {
  // Map over members from the channels perspective
  // to keep the order of members based on the chat
  // so that the avatar is consistent.
  const users = Object.keys(channel.state.members)
    .filter((memberId) => memberId !== channel._client.userID)
    .map((memberId) => data?.usersByIds.find(({ id }) => id === memberId))
    .filter(nullable);
  if (loading) {
    return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton
          containerClassName="flex"
          circle={true}
          height={40}
          width={40}
        />
      </SkeletonTheme>
    );
  }
  return match(users)
    .with([P.select()], (singleUser) => (
      <Avatar
        firstName={singleUser.firstName ?? ""}
        lastName={singleUser.lastName ?? ""}
        randomizeColor
        size="lg"
      />
    ))
    .with(
      [P.select("primaryUser"), ...P.array(P.any).select("otherUsers")],
      ({ primaryUser, otherUsers }) => (
        <div className="w-10 h-10 relative">
          <Avatar
            className="absolute"
            firstName={primaryUser.firstName ?? ""}
            lastName={primaryUser.lastName ?? ""}
            randomizeColor
            size="sm"
          />
          <Pill
            className="absolute bottom-0 right-0 max-w-6 justify-center"
            color={"grey"}
            text={`+${otherUsers.length.toString()}`}
          />
        </div>
      ),
    )
    .otherwise(() => null);
};

export const CommsChannelPreview = (props: ChannelPreviewUIComponentProps) => {
  const {
    channel,
    className: customClassName = "",
    latestMessagePreview,
    lastMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    watchers,
    unread,
  } = props;
  const { channelId } = useParams();
  const isChannelActive = channelId === channel.id;

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const ownId = channel._client.userID;
  const otherMemberIds = Object.keys(channel.state.members).filter(
    (member) => member !== ownId,
  );

  const { data, loading } = useUsersByIdsQuery({
    fetchPolicy: "cache-and-network",
    variables: { ids: otherMemberIds },
  });

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <div
      className={classNames("flex flex-col w-full bg-transparent px-2", {
        // Find the next sibling to hide the top border
        "[&+div>button]:border-transparent": isChannelActive,
      })}
    >
      <button
        className={classNames(
          `channel flex h-17 px-2 py-4 gap-3 cursor-pointer`,
          {
            "channel-active border-transparent basis-0 bg-greyscale-0 dark:bg-greyscale-800 rounded-2xl shadow-[0_2px_6px_-1px_rgba(0,0,0,0.15)] flex-row hover:shadow-[0_2px_6px_-1px_rgba(0,0,0,0.2)] ":
              isChannelActive,
          },
          customClassName,
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
      >
        <div className="flex flex-grow content-center items-center">
          <ChannelAvatar data={data} loading={loading} channel={channel} />
        </div>
        <div className="flex flex-grow w-full">
          <div className="flex flex-col justify-start items-start w-full">
            <div className="text-greyscale-100 flex justify-between w-full">
              <ChannelTitle data={data} loading={loading} />
              {unread && unread > 0 ? (
                <NumberPill
                  className="mr-2"
                  count={unread}
                  color="redSubdued"
                />
              ) : null}
            </div>
            <div className="text-greyscale-400 text-sm font-inclusive font-normal flex flex-row justify-between items-center w-full">
              <div className="truncate flex-1 text-ellipsis w-4 text-start whitespace-nowrap overflow-hidden">
                {latestMessagePreview}
              </div>
              {lastMessage && (
                <div className="flex flex-row flex-end flex-shrink-0 justify-end items-center text-sm px-2 font-inclusive whitespace-nowrap">
                  <div className="px-1">
                    <MessageDeliveryStatusIndicator
                      messageDeliveryStatus={
                        lastMessage?.unread
                          ? MessageDeliveryStatus.DELIVERED
                          : MessageDeliveryStatus.READ
                      }
                    />
                  </div>
                  {getLastMessageTime(lastMessage)}
                </div>
              )}
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};
