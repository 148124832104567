import { Headline } from "../typography";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import { Information } from "../assets/icons/24/filled";
import { match } from "ts-pattern";

type TitleProps = {
  children: React.ReactNode;
};
const Title = ({ children }: TitleProps) => {
  return (
    <DialogTitle
      as={Headline}
      size="l"
      className="mb-2 dark:text-greyscale-100 w-full text-center"
    >
      {children}
    </DialogTitle>
  );
};

type FooterProps = {
  children: React.ReactNode;
  className?: string;
};
const Footer = ({ children, className }: FooterProps) => {
  return (
    <div
      className={classNames(
        "mt-5 grid grid-cols-1 gap-3 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2",
        className,
      )}
    >
      {children}
    </div>
  );
};

type NoteProps = {
  children: React.ReactNode;
  variant: "info" | "warning" | "error";
};

const Note = ({ children, variant }: NoteProps) => {
  const backgroundColor = match({ variant })
    .with({ variant: "info" }, () => "bg-primary-100")
    .with({ variant: "warning" }, () => "bg-accent-400")
    .with({ variant: "error" }, () => "bg-critical-300")
    .exhaustive();

  const iconColor = match({ variant })
    .with({ variant: "info" }, () => "text-primary-600")
    .with({ variant: "warning" }, () => "text-accent-900")
    .with({ variant: "error" }, () => "text-critical-800")
    .exhaustive();

  return (
    <div
      className={classNames(
        "flex p-4 rounded-xl gap-4 items-center",
        backgroundColor,
      )}
    >
      <div>
        <Information className={classNames(iconColor)} />
      </div>
      {children}
    </div>
  );
};

type Props = {
  show: boolean;
  onClose?: () => void;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
  children: React.ReactNode;
};
const Root = ({
  show,
  onClose = () => undefined,
  initialFocus,
  children,
}: Props) => {
  return (
    <Transition show={show} appear as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={initialFocus}
        onClose={onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-greyscale-900 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform rounded-2xl bg-greyscale-0 mx-0.5 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6 dark:bg-greyscale-800 justify-center items-center">
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Modal = Object.assign(Root, { Title, Footer, Note });
export default Modal;
