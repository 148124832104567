import { Label } from "@frontend/lyng/typography";

type Props = {
  title: string;
  subtitle: string;
};

export const Subtitle = (props: { subtitle: string }) => {
  return (
    <label
      id="subtitle"
      className="text-xs font-normal font-inclusive dark:text-greyscale-400"
      htmlFor="subtitle"
    >
      {props.subtitle}
    </label>
  );
};

export const Title = ({ title, subtitle }: Props) => {
  return (
    <div className="flex flex-col pt-2">
      <Label size="m">{title}</Label>
      <Subtitle subtitle={subtitle} />
    </div>
  );
};
