import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { HeadlessService, IMessage } from "@novu/headless";
import {
  AppType,
  useWebPushSubscriptionRegistraterMutation,
} from "../api/generated/graphql";
import { match, P } from "ts-pattern";

type NotificationContextStruct = {
  pageNum: number;
  unseen: number;
  setPageNum: (pageNum: number) => void;
  notifications: IMessage[];
  loading: boolean;
  hasMore: boolean;
  requestMoreNotifications: () => void;
  markNotificationAsRead: (messageId: string) => void;
  markAllMessagesAsRead: (feedId: string) => void;
  markAllMessagesAsSeen: (feedId?: string) => void;
  deleteNotification: (messageId: string) => void;
  registerForWebPushNotifications: (
    pubKey: string | undefined,
    callback: (v: boolean) => void,
  ) => void;
  trackingNotificationClicked?: (count: number, type: string) => void;
};

const subscriptionLocalStorageKey = "webPushSubscriptionSentToServerV3";

const getInitialContext = (): NotificationContextStruct => ({
  pageNum: 0,
  unseen: 0,
  setPageNum: () => ({}),
  notifications: [],
  hasMore: false,
  loading: false,
  requestMoreNotifications: () => ({}),
  markNotificationAsRead: () => ({}),
  markAllMessagesAsRead: () => ({}),
  markAllMessagesAsSeen: () => ({}),
  deleteNotification: () => ({}),
  registerForWebPushNotifications: () => ({}),
});

export const NotificationContext =
  createContext<NotificationContextStruct>(getInitialContext());

type NotificationTrackingFunctions = {
  pushNotificationEnabled: () => void;
  notificationClicked: (count: number, type: string) => void;
  notificationsAllRead: () => void;
};

type NotificationProviderProps = {
  children: React.ReactNode;
  subscriptionData: { hashed: string; unhashed: string } | undefined;
  feedId: string;
  active: boolean;
  trackingFunctions?: NotificationTrackingFunctions;
};

type WebPushSubscriptionStatus = "Allowed" | "Blocked" | "Unset";

const urlB64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const pushManagerRegister = async (
  registration: ServiceWorkerRegistration,
  pubKey: string,
) => {
  return registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(pubKey),
  });
};

const exponentialGetSubscriptionBackoff = (
  registration: ServiceWorkerRegistration,
  registerFunction: (
    subscription: PushSubscription,
    callback: (v: boolean) => void,
  ) => Promise<void>,
  pubKey: string,
  callback: (v: boolean) => void,
  retries = 0,
) => {
  if (retries > 5) {
    const error = new Error("Failed to subscribe to push notifications");
    console.error(error);

    callback(false);
    return;
  }

  setTimeout(
    async () => {
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        registerFunction(subscription, callback);
      } else {
        exponentialGetSubscriptionBackoff(
          registration,
          registerFunction,
          pubKey,
          callback,
          retries + 1,
        );
      }
    },
    Math.pow(2, retries) * 5000,
  );
};

const NotificationProvider = ({
  children,
  subscriptionData,
  feedId,
  active,
  trackingFunctions,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<
    Record<number, IMessage[]>
  >({});
  const [unseen, setUnseen] = useState(0);

  const headlessServiceRef = useRef<HeadlessService | null>(null);
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [webPushSubscriptionStatus, setWebPushSubscriptionStatus] =
    useState<WebPushSubscriptionStatus | null>(null);
  const subscriptionStatus = localStorage.getItem(subscriptionLocalStorageKey);
  const [webPushSubscriptionRegistraterMutation] =
    useWebPushSubscriptionRegistraterMutation();

  const requestMoreNotifications = () => {
    if (!hasMore || loading) {
      return;
    }

    fetchNotifications(pageNum + 1);
  };

  useEffect(() => {
    const status = match<string | null, WebPushSubscriptionStatus>(
      subscriptionStatus,
    )
      .with("true", () => {
        return "Allowed";
      })
      .with("false", () => {
        return "Blocked";
      })
      .otherwise(() => {
        return "Unset";
      });
    setWebPushSubscriptionStatus(status);
  }, [subscriptionStatus]);

  const fetchNotifications = useCallback(
    (pageNum: number) => {
      const headlessService = headlessServiceRef.current;
      let unsubscribe: () => void;
      let stopListening: () => void;
      if (headlessService) {
        unsubscribe = headlessService.fetchNotifications({
          listener: (l) => {
            // Handle the listener here if needed
            setLoading(l.isLoading);
          },
          onSuccess: (response) => {
            // Handle the fetched notifications here.
            setLoading(false);
            setNotifications((prev) => ({
              ...prev,
              [response.page]: response.data,
            }));
            setHasMore(response.hasMore); // Set hasMore state to indicate if there are more notifications to fetch
            setPageNum(response.page); // Set the page number to the current page
            setUnseen(response.data.filter((x) => !x.seen).length); // Set the unseen count
          },
          onError: (error) => {
            console.error("Error fetching notifications:", error);
            // Implement error handling if needed
            unsubscribe && unsubscribe();
          },
          page: pageNum, // page number to be fetched
          query: {
            feedIdentifier: feedId,
          },
        });

        // Whenever we fetch notifications, we want to listen for unseen count changes
        stopListening = headlessService.listenUnseenCountChange({
          listener: (count) => {
            setUnseen(count);
          },
        });
      }
      return () => {
        unsubscribe && unsubscribe();
        stopListening && stopListening();
      };
    },
    [feedId],
  );

  useEffect(() => {
    if (!active || !subscriptionData) {
      return;
    }

    const headlessService = new HeadlessService({
      applicationIdentifier: import.meta.env.VITE_NOVU_APP_ID,
      subscriberId: subscriptionData.unhashed,
      subscriberHash: subscriptionData.hashed,
    });

    headlessService.initializeSession({
      listener: () => ({}),
      onSuccess: () => {
        if (!headlessServiceRef.current) {
          headlessServiceRef.current = headlessService;
        }
        fetchNotifications(0);
      },
      onError: (error) => {
        console.error("headlessSice error:", error);
      },
    });

    return () => {
      headlessServiceRef.current = null;
    };
  }, [fetchNotifications, active, subscriptionData]);

  const markNotificationAsRead = (messageId: string) => {
    const messageIds = [messageId];

    const headlessService = headlessServiceRef.current;

    if (headlessService) {
      headlessService.markNotificationsAsRead({
        messageId: messageIds,
        listener: () => ({}),
        onSuccess: () => {
          setNotifications((prev) => {
            const updatedNotifications = { ...prev };
            Object.keys(updatedNotifications).forEach((key) => {
              const keyNum = parseInt(key, 10);
              updatedNotifications[keyNum] = updatedNotifications[keyNum].map(
                (notification) => {
                  if (notification._id === messageId) {
                    return {
                      ...notification,
                      read: true,
                    };
                  }
                  return notification;
                },
              );
            });
            return updatedNotifications;
          });
        },
        onError: (error) => {
          console.error("Error marking notifications as read:", error);
        },
      });
    }
  };

  const deleteNotification = (messageId: string) => {
    const headlessService = headlessServiceRef.current;
    if (headlessService) {
      headlessService.removeNotification({
        messageId: messageId,
        listener: () => ({}),
        onSuccess: () => ({}),
        onError: (error) => {
          console.error(error);
        },
      });
    }
  };

  const markAllMessagesAsRead = useCallback(
    (feedId: string) => {
      const headlessService = headlessServiceRef.current;
      if (headlessService) {
        headlessService.markAllMessagesAsRead({
          listener: (result) => {
            // Handle the result of marking all messages as read
            // You can update the state or perform other actions here
            console.log("All messages marked as read:", result);
          },
          onSuccess: () => {
            trackingFunctions?.notificationsAllRead();
            // If successful we want to update the state of the notifications in-memory
            setNotifications((prev) => {
              const entries = Object.entries(prev).map(([key, val]) => {
                const newValues = val.map((notification) => ({
                  ...notification,
                  read: true,
                }));
                return [key, newValues];
              });
              return Object.fromEntries(entries);
            });
          },
          onError: (error) => {
            console.error("Error marking all messages as read:", error);
            // Implement error handling if needed
          },
          feedId: feedId, // Pass the feed ID here, it can be an array or a single ID
        });
      }
    },
    [trackingFunctions],
  );

  const markAllMessagesAsSeen = useCallback((feedId?: string) => {
    const headlessService = headlessServiceRef.current;

    if (headlessService) {
      headlessService.markAllMessagesAsSeen({
        feedId: feedId,
        listener: () => ({}),
        onSuccess: () => {
          // If successful we want to update the state of the notifications in-memory
          setNotifications((prev) => {
            const entries = Object.entries(prev).map(([key, val]) => {
              const newValues = val.map((notification) => ({
                ...notification,
                seen: true,
              }));
              return [key, newValues];
            });
            return Object.fromEntries(entries);
          });
          setUnseen(0);
        },
        onError: (error) => {
          console.error("Error marking notifications as seen:", error);
        },
      });
    }
  }, []);

  const sendToServer = useCallback(
    async (auth: string, p256dh: string, endpoint: string) => {
      webPushSubscriptionRegistraterMutation({
        variables: {
          app: feedId === "mobile" ? AppType.Mobile : AppType.Team,
          input: {
            endpoint,
            subscriptionKey: {
              Auth: auth,
              P256dh: p256dh,
            },
          },
        },
      });
    },
    [webPushSubscriptionRegistraterMutation, feedId],
  );

  const registerForWebPushNotifications = useCallback(
    async (pubKey: string | undefined, callback: (v: boolean) => void) => {
      const sendSubscriptionToServer = async (
        subscription: PushSubscription,
        callback: (v: boolean) => void,
      ) => {
        const { keys } = subscription.toJSON();
        if (!keys) {
          return;
        }
        const { auth, p256dh } = keys;
        const endpoint = subscription.endpoint;

        await sendToServer(auth, p256dh, endpoint);

        trackingFunctions?.pushNotificationEnabled();

        localStorage.setItem(subscriptionLocalStorageKey, "true");

        callback(true);
      };

      if (active && pubKey) {
        if (!navigator.serviceWorker) {
          console.error("Service worker not supported");
          return;
        }

        await navigator.serviceWorker
          .getRegistration() //
          .then(async (registration) => {
            if (!registration || !registration.pushManager) return;

            const subscription =
              await registration.pushManager.getSubscription();

            match([webPushSubscriptionStatus, subscription])
              .with(["Unset", P.nonNullable], ([_, subscription]) => {
                sendSubscriptionToServer(subscription, callback);
                // pushManagerRegister(registration, pubKey, callback);
                callback(true);
              })
              .with(["Blocked", P.nonNullable], ([_, subscription]) => {
                sendSubscriptionToServer(subscription, callback);
                // pushManagerRegister(registration, pubKey, callback);
                callback(true);
              })
              .with(["Allowed", P.nullish], () => {
                localStorage.setItem(subscriptionLocalStorageKey, "false");
                callback(true);
              })
              .with(["Allowed", P.nonNullable], () => {
                callback(false);
                // return "Allowed";
              })
              .with(["Blocked", P.nullish], () => {
                callback(false);
                // return "Blocked";
              })
              .with(["Unset", P.nullish], async () => {
                // Push manager register function sucks
                // For whatever reason it returns early with a failure
                // And doesn't wait for the user to confirm or deny the prompt
                pushManagerRegister(registration, pubKey);

                //  So we need to wait for the user to confirm or deny the prompt
                //  And since we can't depend on the pushManagerRegister returning when it should
                //  We need to poll the registration for the subscription
                //  With this exponential backoff function
                exponentialGetSubscriptionBackoff(
                  registration,
                  sendSubscriptionToServer,
                  pubKey,
                  callback,
                );

                callback(false);
              })
              .with([P.nullish, P.any], () => {
                callback(false);
              })
              .exhaustive();
          });
      } else {
        callback(false);
      }
    },
    [active, sendToServer, trackingFunctions, webPushSubscriptionStatus],
  );

  const flattenNotifications = Object.values(notifications).reduce(
    (acc, val) => acc.concat(val),
    [],
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications: flattenNotifications,
        unseen,
        markNotificationAsRead,
        markAllMessagesAsSeen,
        markAllMessagesAsRead,
        deleteNotification,
        pageNum,
        setPageNum,
        hasMore,
        requestMoreNotifications,
        loading,
        registerForWebPushNotifications,
        trackingNotificationClicked: trackingFunctions?.notificationClicked,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotificationContext() {
  return useContext(NotificationContext);
}

export { useNotificationContext, NotificationProvider };
