import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";
import Linkify from "linkify-react";
import { PriorityIcon } from "@frontend/lyng/common/Priority";
import { ActivitySchedule, Priority } from "../../../api/generated/graphql";
import { formatTime } from "../../../utils/dateUtils";
import { Headline, Label } from "@frontend/lyng/typography";

type Props = {
  priority: Priority;
  title: string;
  isDue: boolean;
  schedule: ActivitySchedule | null;
  lastCompletedAt: string | null;
  description: string;
  start?: DateTime;
};

const getIconAndText = (priority: Priority) => {
  if (priority === Priority.High)
    return {
      icon: <PriorityIcon className="mr-1" priority={Priority.High} />,
      text: "priorities.high",
    };
  if (priority === Priority.Medium)
    return {
      icon: <PriorityIcon className="mr-1" priority={Priority.Medium} />,
      text: "priorities.medium",
    };
  if (priority === Priority.Low)
    return {
      icon: <PriorityIcon className="mr-1" priority={Priority.Low} />,
      text: "priorities.low",
    };
  return { icon: null, text: null };
};

function calculateDaysUntilNextDue(
  intervalDays: number,
  lastCompletedAt: string | null,
): number {
  const today = DateTime.local().startOf("day");

  const lastCompletedDate = lastCompletedAt
    ? DateTime.fromISO(lastCompletedAt).startOf("day")
    : null;

  if (!lastCompletedDate) {
    return 0;
  }

  const daysSinceLastCompleted = today.diff(lastCompletedDate, "days").days;

  if (daysSinceLastCompleted === 0) {
    return intervalDays;
  } else if (daysSinceLastCompleted % intervalDays === 0) {
    return 0;
  } else {
    const daysSinceLastCompletionModulo = daysSinceLastCompleted % intervalDays;
    return intervalDays - daysSinceLastCompletionModulo;
  }
}

export const ActivityCard = ({
  priority,
  isDue,
  schedule,
  lastCompletedAt,
  title,
  description,
  start,
}: Props) => {
  const { t } = useTranslate();

  function getDueInText(daysUntilNextDue: number): string {
    if (daysUntilNextDue === 0) {
      return t("activities.dueToday");
    } else if (daysUntilNextDue === 1) {
      return t("activities.dueInOneDay");
    } else {
      return t("activities.dueInXDays", { daysUntilNextDue });
    }
  }

  const getScheduleDescription = (
    schedule: ActivitySchedule,
    lastCompletedAt: string | null,
  ): string => {
    if (schedule.__typename === "ActivityScheduleEveryNDays") {
      const intervalDays = schedule.days;

      const daysUntilNextDue = calculateDaysUntilNextDue(
        intervalDays,
        lastCompletedAt,
      );

      const dueInText = getDueInText(daysUntilNextDue);

      return t("activities.repeatsEvery", { intervalDays, dueInText });
    } else if (schedule.__typename === "ActivityScheduleEveryVisit") {
      return t("activities.repeatsEveryVisit");
    } else if (schedule.__typename === "ActivityScheduleFollowVisitSeries") {
      return t("activities.followVisitSeries");
    }

    return "";
  };

  const scheduleDescription = schedule
    ? getScheduleDescription(schedule, lastCompletedAt)
    : "";

  const { icon, text } = getIconAndText(priority);

  return (
    <div className="text-title">
      <Headline size="m">{title}</Headline>
      <div className="flex items-center gap-2">
        {icon}
        {!isDue && (
          <Label size="xxs" className="text-subtitle">
            {scheduleDescription}
          </Label>
        )}
        {start && isDue && (
          <Label size="xxs" className="text-subtitle">
            {formatTime(start, false)}
          </Label>
        )}
        {text && isDue && (
          <Label size="xxs" className="text-subtitle">
            {t(text)}
            {schedule ? ` - ${scheduleDescription}` : ""}
          </Label>
        )}
      </div>
      <p className="whitespace-pre-line pt-4">
        <Linkify
          options={{
            className: "text-primary-90 font-semibold break-all",
            target: "_blank",
          }}
        >
          {description}
        </Linkify>
      </p>
    </div>
  );
};
